
import axiosExcecute from './peticionApi';
// obtenemos los tokens
import { SaveCookeSession } from './cookieSession';
import { _SERVERBRIVE } from '../settings';
import Cookies from 'universal-cookie';
import https from 'https'
import axios from 'axios';
const cookies = new Cookies();
var detectrtc = require('detectrtc');



export const getTokeJWT = async (refresh_token) => {
    try {
        

        const params = new URLSearchParams();
        
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refresh_token);

        let res = await axios({
            method: 'post',
            url: `${_SERVERBRIVE}/auth/token`,
            data: params,
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        })
        return res;

    } catch (error) {
        return 500;
    }
}

export async function refreshTokenBrive() {
    try {
        var auth = cookies.get('auth');
        var token = auth.refres_toke;
        var response = await getTokeJWT(token);

        const { access_token, refresh_token } = response.data
        
        auth.tokenBrive = access_token;
        auth.refres_toke = refresh_token;
        // guardamos la cookie
        SaveCookeSession(auth);
        return true;
    } catch (error) {
        return false;
    }
}

export async function getIp() {
    try {
        const response = await axiosExcecute({
            url: `https://api.ipify.org`,
            method: 'get',
        });
        return response.data;
    } catch (error) {
        return "";
    }
}