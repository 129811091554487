import React, { memo, useEffect, useState } from 'react'
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from 'draft-js'

import TextoEnrriquecido from '../../../componentes/textoEnrriquecido/TextoEnrriquecido'
import { AlertDangerousText } from '../../Security/components/AlertDangerousText'

import { isValidHTMLString } from '../../Security/helpers'
import './NotesTextContainer.css'

export const NotesTextContainer = memo(({
  value,
  onChange,
  valid,
  onChangeValid,
}) => {

  useEffect(() => {
    if (value) {
      const text = draftToHtml(convertToRaw(value.getCurrentContent()))
      const isValid = isValidHTMLString({ html: text })
      onChangeValid(isValid)
    }
  }, [value])

  return (
    <>
      <AlertDangerousText
        className="mb-3"
        secure={valid}
      />
      <div className={valid ? 'reach-text-valid' : 'reach-text-invalid'}>
        <TextoEnrriquecido
          respuesta={value}
          onEditorStateChange={onChange}
        /> 
      </div>
    </>
  )
})
