import React, { Component } from 'react';
import OpenViduVideoComponent from './OvVideo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';

export default class UserVideoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            iniciales: ""
        }
    }

    componentDidMount() {
        this.getAvatar();
        this.getIniciales();
    }

    /**
     * Método que obtiene el nombre del participante mediante el data de openvidu
     * @returns string con el nombre del participante
     */
    getNicknameTag() {
        try {
            var nickname = "";
            // obtenemos el nombre del participante de la variable de sesion de openvidu
            var userName = JSON.parse(this.props.streamManager.stream.connection.data).clientData;
            if (userName !== undefined) {
                var palabras = userName.split(" ");
                var total = palabras.length;
                if (total > 1) {
                    nickname = palabras[0] + " " + palabras[1];
                }
                else{
                    nickname = palabras[0]
                }
            }
            return nickname;
        } catch (error) {
            return "";
        }
    }

    /**
     * Método que obtiene las iniciales del participante
     * @returns string con las iniciales del participante
     */
    getIniciales(){
        try {
            var iniciales = "UD";
            var userName = this.getNicknameTag();
            if (userName !== undefined) {
                var palabras = userName.split(" ");
                var total = palabras.length;
                if (total > 1) {
                    iniciales = palabras[0][0] + palabras[1][0];
                }
                else{
                    iniciales = palabras[0][0]
                }
            }
            return iniciales;

        } catch (error) {
            return "";
        }
    }

    /**
     * Método que obtiene el avatar del participante de la variable de sesión de openvidu
     * @returns String con la url de la imagen del avatar del participante
     */
    getAvatar(){
        try {
            var urlAvatar = JSON.parse(this.props.streamManager.stream.connection.data).avatar;

            return urlAvatar;
        } catch (error) {
            return null;
        }
    }

    render() {
        return (
            <div className="streamcomponent">
                {this.props.streamManager !== undefined ? (
                    <div className="contenedor-stream">
                        <div className="nombre-participante">
                            <p>
                                {this.getNicknameTag()} 
                                {(!this.props.streamManager.stream.audioActive) ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : null } 
                            </p>
                        </div>
                        {(!this.props.streamManager.stream.videoActive) &&
                            <div className="nombre-sin-video" >
                                {(this.getAvatar() === undefined || this.getAvatar() === null) &&
                                    <label className="nombre-sin-video__circulo">{this.getIniciales()}</label>
                                }
                                
                                {(this.getAvatar() !== undefined && this.getAvatar() !== null) &&
                                    <img src={this.getAvatar()} alt="" className="nombre-sin-video__avatar" />
                                }
                            </div>
                        }
                        {/* {(this.props.streamManager.stream.videoActive) && */}
                        <div style={{display : this.props.streamManager.stream.videoActive ? "flex" : "none"}}  className="contenedor-stream">
                            <OpenViduVideoComponent streamManager={this.props.streamManager} />
                        </div>
                            
                        {/* } */}

                    </div>
                ) : null}
            </div>
        );
    }
}