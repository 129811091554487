import React from 'react';
import {Row, Col, Spinner, Button, Alert} from 'react-bootstrap'
import { _SERVER } from '../../settings';

// ejecutor axios
import axiosExcecute from '../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../lib/cookieSession';
// objetos fetch
import { HeaderGet } from '../../lib/fecthObject';
import { message } from 'antd';
const axios = require('axios');
class ComponenteRechazo extends React.Component{

    constructor(props){
        super(props);
    }

    state = {
        mensaje: this.props.mensaje,
        imagen: this.props.imagen,
        campoRequeridoMotivo : "ocultar",
        motivos: "",
        accessCode: this.props.accessCode,
        scheduleDate: new Date(),
        timeZoneId: "",
    }

    componentDidMount = async () =>{
        await this.getDateSchedule();
    }

    /**
     * Método que obtiene la fecha, hora de inicio y hora de fin de una agenda
     */
    getDateSchedule = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/date/accessCode/${this.state.accessCode}`;
            const fetchSchedule = HeaderGet(url, tokenVideo);
            
            let res = await axiosExcecute(fetchSchedule);
            
            if (res.status == 200 && res.data !== null) {
                var dateSchedule = new Date(res.data.interviewDate);

                this.setState({
                    timeZoneId: res.data.timeZone,
                    scheduleDate: dateSchedule
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la información de la agenda.");
        }
    }

    /**
     * Método que valida que los datos requeridos para cancelar la agenda esten llenos.
     */
    handleSendForm = () => {
        var motivo = this.state.motivos;
        if (motivo !== undefined && motivo.trim().length > 0) {
            this.setState({
                campoRequeridoMotivo : "ocultar",
            });
            
            var form = {
                timeZoneId: this.state.timeZoneId,
                motivos: this.state.motivos,
                proposedDate: this.state.scheduleDate,
            }
            this.props.handleForm(form);
        }
        else
        {
            this.setState({
                campoRequeridoMotivo : "label-advertencia",
            });
        }
    }
    
    /**
     * Método que obtiene el valor del input de motivos
     * @param {Evento de input de motivos} event 
     */
    handleChange = event => {
        this.setState({
            motivos: event.target.value,
        });

    }

    render()
    {
        const { mensaje, imagen } = this.state
        return(
            <div className="mt-5 pt-2">
                <section className=" contendor-confirmacion-rechazo container">
                    <div className="row">
                        <img className="pt-2 contendor-confirmacion__imagen mx-auto d-block" src={imagen} />
                    </div>
                    
                    <div className="row titulo-posponer">
                        <div className="col-md-12 center titulo-posponer__principal">
                            <label >{mensaje}</label>
                        </div>
                        <div className="col-md-12 center titulo-posponer__subtitulo">
                            <label >Por favor indica el motivo:</label>
                        </div>
                    </div>

                    <div className="contenedor-form " >

                        <Row className="justify-content-md-center">
                            <Col md={11} className="alinear-centro">
                                <div className="contendor-confirmacion-inputs">
                                    <textarea className="motivos-propuesta" maxlength="950" name="motivios" rows="4" placeholder="ej. Ya no me interesa la vacante" onChange={this.handleChange}></textarea>
                                    <label className={this.state.campoRequeridoMotivo}>El campo es requerido</label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center pb-4">
                            <Col md={11} className="alinear-centro">
                                {this.props.showAlert === true &&
                                    <div className="contendor-confirmacion-inputs">
                                        <Alert variant={this.props.styelAlert} className="estiloAlert mt-4 mb-0" onClose={this.props.closeAlert} dismissible>
                                            <p className="p-0">{this.props.mensajeAlert}</p>
                                        </Alert>
                                    </div>
                                }
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center pb-4">
                            <Col md={11}>
                                {this.props.loading ?
                                    <Button variant="primary" disabled className="btn-block boton-posponer">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}Guardando...
                                    </Button>
                                    : <button className="btn btn-block btn-primary mb-4 boton-posponer boton-posponer__titulo" type="button" onClick={() => this.handleSendForm()} disabled={this.props.habilitarAccion} >
                                        {this.props.msjButton}
                                    </button>
                                }
                            </Col>
                        </Row>

                    </div>
                </section>
            </div>
        )
    }
}

export default ComponenteRechazo;