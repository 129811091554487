import React, { useState, useEffect } from 'react';
import { OpenVidu } from 'openvidu-browser';

const openVidu = new OpenVidu();

const useOpenViduCompatibility = () => {
  const [ compatibility, setCompatibility ] = useState(false);

  useEffect(
    () => {
      /**
       * Checks if the browser supports OpenVidu
       * Returns number
       * 1 if the browser supports OpenVidu, 0 otherwise
       * https://docs.openvidu.io/en/2.13.0/api/openvidu-browser/classes/openvidu.html#checksystemrequirements
       */
      const opCompatibility = openVidu.checkSystemRequirements();
      setCompatibility(Boolean(opCompatibility));
    },
    [openVidu]
  );

  return { compatibility };
};

export default useOpenViduCompatibility;