import React from "react";
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard} from '@fortawesome/free-solid-svg-icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class ModalCompartirArchivo extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            copied : false,
            value : props.url,
            showToltip: false
        }
    }

    copiado = () =>{
        this.setState({
            copied : true,
            showToltip: true
        });

        setTimeout ( ()=> {
            this.setState({
                showToltip: false,
            })
        },2000);
    }

    render(){
        return(
            <Modal show={this.props.show} onHide={this.props.onHide} aria-labelledby="contained-modal-title-vcenter" centered   >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Compartir archivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <InputGroup>
                                <input
                                    className="form-control"
                                    placeholder="Correo electrónico"
                                    name="urlCompartir"
                                    value={this.props.url}
                                    readOnly
                                />
                                <InputGroup.Append>
                                    <CopyToClipboard text={this.props.url}
                                        onCopy={() => this.copiado()}>
                                        <Button variant="outline-secondary"><FontAwesomeIcon icon={faClipboard} title="Copiar al portapapeles" /></Button>
                                    </CopyToClipboard>
                                </InputGroup.Append>
                            </InputGroup>
                            <div className="contenedor-copiar-clib">
                                {(this.state.showToltip) ? <label>Copiado</label> : null}
                            </div>
                            
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalCompartirArchivo;