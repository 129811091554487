import Cookies from 'universal-cookie';
 
const cookies = new Cookies();

export function SaveCookeSession(userData) {
    var userDataString = JSON.stringify(userData);
    cookies.set("auth", userDataString, { path: '/' });
    return true;
}

export function DeleteCookieSession(){
    var rutaRedireccion = "/login/interviewer";
    if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
        var auth = cookies.get('auth');
        if (!auth.user.isCandidate) {
            rutaRedireccion = "/login/interviewer";
        }else{
            rutaRedireccion = "/login/candidato";
        }
    }
    cookies.remove("auth", { path: '/' });
    window.location.href= rutaRedireccion;
    return true;
}

export function GetTokenBrive(){
    try {
        var token = "";
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            token = auth.tokenBrive;
        }
        return token;
    } catch (error) {
        return undefined;
    }
}

export function GetAuth(){
    try {
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            return auth;
        }
        else
        {
            return undefined;
        }
    } catch (error) {
        return undefined;
    }
}

export function GetTokenVideoEntrevista(){
    try {
        var token = "";
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            token = auth.tokenVE;
        }
        return token;
    } catch (error) {
        return undefined;
    }
}

export function GetTokenGuiaEntrevista(){
    try {
        var token = "";
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            token = auth.tokenGuia;
        }
        return token;
    } catch (error) {
        return undefined;
    }
}

export function GetEmailParticipant(){
    try {
        var token = "";
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            token = auth.user.email;
        }
        return token;
    } catch (error) {
        return undefined;
    }
}