import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from 'draft-js'

import TextoEnrriquecido from '../../../componentes/textoEnrriquecido/TextoEnrriquecido'
import { AlertDangerousText } from '../../Security/components/AlertDangerousText'

import { isValidHTMLString } from '../../Security/helpers'
import './ReachTextContainer.css'

export const ReachTextContainer = ({
  id,
  pregunta,
  onEditorStateChange,
  onShowIndicator,
  stashInvalidInput,
  popInvalidInput,
}) => {
  const [validText, setValidText] = useState(true)

  useEffect(() => {
    if (pregunta.answer) {
      const text = draftToHtml(convertToRaw(pregunta.answer.getCurrentContent()))
      const isValid = isValidHTMLString({ html: text })
      setValidText(isValid)

      if (isValid) {
        popInvalidInput(id)
      } else {
        stashInvalidInput(id)
      }
    }
  }, [pregunta.answer])

  const handleChange = (event) => {
    onEditorStateChange(pregunta, event)
  }

  return (
    <div className="pregunta-profunda">
        <label>p: {pregunta.text}</label>
        <Row>
            <AlertDangerousText
              className="my-2"
              secure={validText}
            />
            <Col
              className={validText ? 'reach-text-valid' : 'reach-text-invalid'}
              sm={12}
              md={24}
            >
                <TextoEnrriquecido
                    respuesta={(pregunta.answer)}
                    onEditorStateChange={handleChange}
                    onShowIndicator={onShowIndicator}
                />
            </Col>
        </Row>
    </div>
  )
}
