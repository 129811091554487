import { HeaderGet } from "../../lib/fecthObject";
import axiosExcecute from "../../lib/peticionApi";
import { _SERVER_BRIVE_CANDIDATE } from "../../settings";

/** 
 * Endpoint que permite cancelar una entrevista desde el dsahboard de entrevistas
* @param {string} token: Id de la entrevista a cancelar 
*/
export const getCandidateInformation = async ({ token }) => {
  const url = `${_SERVER_BRIVE_CANDIDATE}/api/v1/candidates`;
  const fetch = HeaderGet(url, token);
  const response = await axiosExcecute(fetch);
  return response;
}