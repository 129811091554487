import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import './CierreEntrevista.css';
<link rel="stylesheet" href="node_modules/react-star-rating/dist/css/react-star-ratings.min.css"></link>

class CierreEntrevista extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        cierreEntrevista: false,
        form: {
            conclusion: this.props.data.conclusion,
            score: this.props.data.score
        },
        colorStars:"#F8CB00",
    };

    cancelar = () => {
        this.props.onHide();
    }

    aceptar = () => {
        if (this.state.form.score >= 0)
        {
            this.setState({
                colorStars:"#F8CB00",
            });
            this.props.handleModalCandidato(this.state.form);
            this.props.onHide();
        }
        else
        {
            this.setState({
                colorStars:"#FF0000",
                form: {
                    score: 5,
                },
            });
            setTimeout(() => {
                this.setState({
                    colorStars:"#F8CB00",
                    form: {
                        score: 0,
                    }
                });
            }, 200);
        }
        
    }

    changeRating = (newRating) => {
        this.setState({
            form: {
                score: newRating,
                conclusion : this.state.form.conclusion
            }
        });
    }

    handleChange = event => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value,
                score: this.state.form.score
            }
        })
    }
    render() {
        return (
            <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered   >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="titulo-modal-candidato">
                        Cierre de entrevista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-candidato">
                    <section className="ml-3 mr-3">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="modal-candidato__text">¿Cómo fue tu experiencia en la entrevista?</label>
                                </div>
                                <div className="modal-candidato__stars mb-3">
                                    <StarRatings rating={this.state.form.score} starRatedColor={this.state.colorStars} starHoverColor="#F8CB00" changeRating={this.changeRating} numberOfStars={5} name='score' />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="modal-candidato__text">¿Tienes algún comentario para compartir?</label>
                                    <textarea className="modal-candidato__text-area modal-candidato__text" maxlength="950" name="conclusion" rows="4" cols="50" placeholder="Ej. Mi proceso de entrevista fue ágil y sencillo; el entrevistador fue muy amable." onChange={this.handleChange} value={this.state.form.conclusion}></textarea>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="mr-3 modal-footer-candidato__boton-cancelar texto--bold" onClick={this.cancelar} >Cancelar</button>
                    <button className="modal-footer-candidato__boton-aceptar texto--bold" onClick={this.aceptar}>Aceptar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CierreEntrevista;