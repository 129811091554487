import { Popover } from 'antd'
import React from 'react'

import NewFeaturesImage from "../../images/newTooltip.svg";


const NewFeaturesTooltip = ({title, content}) => {
  return (
    <>
        <Popover 
            content={content} 
            title={title} 
            trigger="hover" 
            placement="bottomLeft"
            overlayStyle={{ fontSize: "14px", width: "248px" }}
      >
        <img
          src={NewFeaturesImage}
          alt="tooltip"
          style={{position: "absolute", top: -10, left: 150}}
          className="animation_badge"
        />
        </Popover>
    </>
  )
}

export default NewFeaturesTooltip