
export function HeaderGet(url, token){

    const get = {
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }
    };
    return get;
}

export function HeaderGetFile(url, token){

    const get = {
        method: 'get',
        url: url,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    };
    return get;
}

export function HeaderPost(url, token, data){

    const post = {
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }
    };
    return post;
}

export function HeaderPut(url, token, data){

    const put = {
        method: 'put',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }
    };
    return put;
}

export const createFetch = ({token, method, url, data}) => {
    return ({
        method,
        url,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        },
        data: data ? data : null,
    });
}