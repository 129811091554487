import React, { useRef, useEffect, useState } from "react";
import { Card, Col, Row, Alert, InputGroup, FormControl, Button, Tabs, Tab, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/perfil-icon.jpg';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { _SERVER, _TOKEN, _FILE_VALID, _MB_MAX_FILES } from "../../../settings";
import { encodeFileToBase64 } from "../../../helpers"
import ModalCompartirArchivo from "./ModalCompartirArchivo";
import { Tooltip } from 'antd';
import shared from "../../images/shared.svg";
import dowloand from "../../images/download.svg";
import fileImage from "../../images/file24px.svg";
import send from "../../images/send24px.svg";
// ejecutor axios
import axiosExcecute from '../../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../../lib/cookieSession';
// objetos fetch
import { HeaderPost, HeaderGetFile, HeaderGet } from '../../../lib/fecthObject';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { message } from 'antd';

const Chat = (props) => {

    const { dataCandidate, interviewers, isCandidate, objSesion, participantId, interviewId, listParticipants } = props;
    const [nameSocketInterviewer, setNameSocket] = useState([]);
    const [chatsPrevios, setChatsPrevios] = useState([]);
    const [notifications, setNotification] = useState([
        {
            id: objSesion.accessCode,
            active: false
        }, {
            id: dataCandidate.id,
            active: false
        }
    ]);
    const [interviewersActive, setInterviewersActive] = useState([]);

    useEffect(() => {
        createNameSocketInterviewers();
        obtenerChatsPrevios();
    }, [])

    useEffect(() => {
        if (nameSocketInterviewer.length > 0) {
            obtenerChatsPrevios();
        }
    }, [nameSocketInterviewer])

    useEffect(() => {
        createListInterviewerActive();
    }, [props.listParticipants.length])

    const createListInterviewerActive = () => {
        let listTem = interviewersActive;
        listParticipants.forEach(participant => {
            let dataParticipant = interviewers.filter(item => item.id === participant.participantId);

            let existParticipant = listTem.filter(item => item.id === participant.participantId);

            if (dataParticipant.length > 0 && existParticipant.length === 0) {
                listTem.push(dataParticipant[0]);
            }
        })

        setInterviewersActive(listTem);
    }


    const valueLenght = (value) => {
        return value <= 9 ? "0" + value : value;
    }

    /**
     * Crea los diferentes id de los canales que estaran disponibles en una video entrevista
     * de entrevistador a entrevistador
     */
    const createNameSocketInterviewers = () => {

        if (interviewers.length === 1) {
            setNameSocket([{
                id: interviewers[0].id,
                nameSocket: interviewers[0].id
            }])
        } else {
            interviewers.forEach((interviewer, index) => {
                setNotification(n => [...n, { id: interviewer.id, active: false }]);
                interviewers.forEach((_interviewer, _index) => {
                    if (interviewer.id !== _interviewer.id) {
                        let nameSocket = `${interviewer.id}${_interviewer.id}`
                        if (index > _index)
                            nameSocket = `${_interviewer.id}${interviewer.id}`
                        setNameSocket(nS => [
                            ...nS,
                            {
                                id: _interviewer.id,
                                nameSocket
                            }
                        ]);
                    }
                });
            });
        }
    }

    const obtenerChatsPrevios = async () => {

        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/chat/files/interview/${interviewId}`;
            const fetchChat = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchChat);
            if (res.status === 200) {

                let chatsSaved = [];
                res.data.forEach(chatPrevio => {
                    // identificamos los nombres de los participantes
                    if (chatPrevio.isTransmitterCandidate) {
                        chatPrevio.transmiterName = dataCandidate.name;
                    }
                    else {
                        let intervieSelectList = interviewers.filter(i => i.id === chatPrevio.transmitterId);
                        if (intervieSelectList.length > 0) {
                            chatPrevio.transmiterName = intervieSelectList[0].name;
                        }
                    }
                    // identificamos los canales
                    if (chatPrevio.isGroup) {
                        chatPrevio.methodName = objSesion.accessCode;
                    }
                    else if (chatPrevio.isReceptorCandidate) {
                        chatPrevio.methodName = `${dataCandidate.id}${chatPrevio.transmitterId}${objSesion.accessCode}`;
                    }
                    else if (chatPrevio.isTransmitterCandidate) {
                        chatPrevio.methodName = `${dataCandidate.id}${chatPrevio.receptorId}${objSesion.accessCode}`;
                    }
                    else {
                        if (interviewers.length === 1) {
                            var sockenFilter = nameSocketInterviewer.filter(objName =>
                            (objName.nameSocket === `${chatPrevio.transmitterId}`
                                || objName.nameSocket === `${chatPrevio.receptorId}`));

                            if (sockenFilter.length > 0) {
                                chatPrevio.methodName = sockenFilter[0].nameSocket;
                            }
                        }
                        else {
                            var sockenFilter = nameSocketInterviewer.filter(objName =>
                            (objName.nameSocket === `${chatPrevio.transmitterId}${chatPrevio.receptorId}`
                                || objName.nameSocket === `${chatPrevio.receptorId}${chatPrevio.transmitterId}`));

                            if (sockenFilter.length > 0) {
                                chatPrevio.methodName = sockenFilter[0].nameSocket;
                            }
                        }
                    }


                    chatsSaved.push(chatPrevio);
                });
                setChatsPrevios(chatsSaved);
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener los mensajes enviados anteriormente");
        }

    }

    const clearNotification = (key) => {

        let _notification = notifications;
        if (key === 'allParticipant') {
            _notification[0].active = false
        }
        setNotification(_notification)
    }


    /**Actualizar */
    const _setNotification = (data) => {
        setNotification(data);
    }

    /**
     * Selecciona el id del canal que corresponde a cada paraticipante en la video entrevista
     * @param {*} interviewer 
     * @returns 
     */
    const createNameSocket = (interviewer) => {
        if (isCandidate) {
            return `${dataCandidate.id}${interviewer.id}${objSesion.accessCode}`
        } else {
            const objNameSocket = nameSocketInterviewer.filter(
                objName =>
                    objName.id === interviewer.id &&
                    objName.nameSocket.includes(participantId)
            );

            if (objNameSocket.length > 0) {
                return objNameSocket[0].nameSocket;
            }
        }
    }

    const getNameParticipant = (userName, userId, isCandidato) => {
        try {
            var participantName = "";
            if (userName !== null && userName !== undefined) {
                var palabras = userName.split(" ");
                var total = palabras.length;
                if (total > 0) {
                    participantName = palabras[0];
                }
            }
            else {
                if (isCandidato) {
                    participantName = "Candidato";
                }
                else {
                    participantName = "Usuario " + userId;
                }
            }
            return participantName;
        } catch (error) {
            message.error("Ocurrió un error al obtener el nombre del participante");
            return "";
        }
    }

    return (
        <div>
            <NotificationContainer />
            <Card className="border-0">
                <Card.Body className="header-tab">
                    <label className="header-tab__title">Chat</label>
                </Card.Body>
            </Card>

            <Card className="border-0">
                <Card.Body className="pt-1 pr-1 pb-1 card-body-tabs">
                    <Row>
                        <Col sm={12} >
                            <Tabs className="tab-chats pb-1" defaultActiveKey="allParticipant">
                                {interviewersActive.length > 1 &&
                                    <Tab className="m-0 p-0 mt-4" eventKey="allParticipant" onClick={() => clearNotification('allParticipant')} title={
                                        <div className="chat-header" onClick={() => clearNotification('allParticipant')}>
                                            <FontAwesomeIcon icon={faUserFriends} className="tab-chats__all-chat icono-accion-todos mt-2 ml-2 mr-2" />
                                            <label style={{ margin: "0px" }}>Todos</label>
                                            {
                                                (String(notifications[0].active) === 'true') ? <FontAwesomeIcon icon={faCircle} style={{ color: "orange" }} className="ml-2" /> : null
                                            }
                                        </div>
                                    }>
                                        <section className="mt-2">
                                            <Messages
                                                notifications={notifications}
                                                setNotification={_setNotification}
                                                dataCandidate={dataCandidate}
                                                interviewers={interviewers}
                                                isCandidate={isCandidate}
                                                objSesion={objSesion}
                                                participantId={participantId}
                                                chat={objSesion.accessCode}
                                                valueLenght={valueLenght}
                                                interviewId={interviewId}
                                                isGroup={true}
                                                receptorId={null}
                                                isReceptorCandidate={false}
                                                chatsPrevios={chatsPrevios}
                                            />
                                        </section>
                                    </Tab>
                                }
                                {
                                    !isCandidate && dataCandidate.id !== undefined &&
                                    <Tab eventKey="candidate" title={
                                        <div className="chat-header">
                                            <div className="chat__avatar">
                                                <img src={dataCandidate.urlAvatar != null ? dataCandidate.urlAvatar : logo} alt="" />
                                            </div>
                                            <label style={{ margin: "0px" }}>{getNameParticipant(dataCandidate.name, "1", true)}</label>
                                        </div>
                                    }>
                                        <section className="mt-2">
                                            <Messages
                                                notifications={notifications}
                                                setNotification={_setNotification}
                                                dataCandidate={dataCandidate}
                                                interviewers={interviewers}
                                                isCandidate={isCandidate}
                                                objSesion={objSesion}
                                                participantId={participantId}
                                                chat={`${dataCandidate.id}${participantId}${objSesion.accessCode}`}
                                                valueLenght={valueLenght}
                                                interviewId={interviewId}
                                                isGroup={false}
                                                receptorId={`${dataCandidate.id}`}
                                                isReceptorCandidate={true}
                                                chatsPrevios={chatsPrevios}
                                            />
                                        </section>
                                    </Tab>
                                }
                                {nameSocketInterviewer.length > 0 &&
                                    interviewersActive.map((participante, i) => (
                                        participantId !== participante.id &&
                                        <Tab key={i} eventKey={participante.id} title={
                                            <div className="chat-header">
                                                <div className="chat__avatar">
                                                    <img src={participante.urlAvatar != null ? participante.urlAvatar : logo} alt="" />
                                                </div>
                                                <label>{getNameParticipant(participante.name || participante.email.split('@')[0], participante.id, false)}</label>
                                            </div>
                                        }>
                                            <section className="mt-2">
                                                <Messages
                                                    notifications={notifications}
                                                    setNotification={setNotification}
                                                    dataCandidate={dataCandidate}
                                                    interviewers={interviewers}
                                                    isCandidate={isCandidate}
                                                    objSesion={objSesion}
                                                    participantId={participantId}
                                                    chat={createNameSocket(participante)}
                                                    valueLenght={valueLenght}
                                                    interviewId={interviewId}
                                                    isGroup={false}
                                                    receptorId={participante.id}
                                                    isReceptorCandidate={false}
                                                    chatsPrevios={chatsPrevios}
                                                />
                                            </section>
                                        </Tab>
                                    ))}
                            </Tabs>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );

}


const Messages = (props) => {

    const {
        notifications,
        setNotification, //<== aca lo recibo D
        valueLenght,
        participantId,
        objSesion,
        interviewId,
        isGroup,
        receptorId,
        isReceptorCandidate,
        isCandidate,
        interviewers,
        dataCandidate,
        chatsPrevios,
    } = props;

    const messagesEndRef = useRef(null);
    const [chat, setChat] = useState({});
    const [valorTxt, setValorTxt] = useState('');
    const [mensajesChat, setMensajes] = useState([]);
    const [connection, setConnection] = useState(null);
    const [isFile, setIsFile] = useState(false)
    const [file, setFile] = useState("")
    const [modalShare, setModalShare] = useState(false)
    const [urlFile, setUrlFile] = useState("");
    const [loadingFile, setLoadingFile] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [mensajeValidacion, setMensajeValidacion] = useState("");
    const [fileString, setFileString] = useState("");

    const chanelId = props.chat;

    const dataTrasmiter = (transmiterData) => {
        if (transmiterData.isCandidate) {
            if (dataCandidate.name !== null && dataCandidate.name !== undefined) {
                return dataCandidate.name.split(" ")[0]
            }
            else {
                return "";
            }
        } else {
            let _dataTrasmiter = interviewers.filter(transmiter => transmiter.id === transmiterData.participanId);
            if (_dataTrasmiter.length > 0 && _dataTrasmiter[0].name !== null && _dataTrasmiter[0].name !== undefined) {
                return _dataTrasmiter[0].name.split(" ")[0];
            }
            else {
                return "";
            }
        }
    }

    const handleFileInput = async (e) => {
        if (e.target.files[0] !== undefined) {
            var extensionesValidas = _FILE_VALID.split(",");
            // obtenemos la extension del archivo subido
            var extension = getFileExtension2(e.target.files[0].name);
            var fileSizeMB = (e.target.files[0].size / 1024) / 1024;
            if (extensionesValidas.includes("." + extension)) {
                if (fileSizeMB < _MB_MAX_FILES) {
                    setFile(e.target.files[0])
                    setIsFile(true);
                    var nombreParticipante = objSesion.nombreParticipant
                    if (nombreParticipante == null || nombreParticipante == undefined) {
                        nombreParticipante = objSesion.email;
                    }
                    setDataChat(`${nombreParticipante} ha enviado un archivo`, true);
                }
                else {
                    setIsVisible(true);
                    setMensajeValidacion("Archivo excedio el peso limite de " + _MB_MAX_FILES + " MB");
                    setTimeout(() => {
                        setIsVisible(false);
                        setMensajeValidacion("");
                    }, 4000);
                }
            }
            else {
                setIsVisible(true);
                setMensajeValidacion("Formato no válido");
                setTimeout(() => {
                    setIsVisible(false);
                    setMensajeValidacion("");
                }, 4000);
            }
        }
    }

    const getFileExtension2 = (filename) => {
        return filename.split('.').pop();
    }

    const formatDate = (date) => {
        let fecha = new Date(date);
        let fechaActual = fecha.getFullYear() + "-" + valueLenght(fecha.getMonth() + 1) + "-" + fecha.getDate();
        let horaActual = valueLenght(fecha.getHours()) + ":" + valueLenght(fecha.getMinutes());

        return `${fechaActual} / ${horaActual}`
    }

    const handleChange = event => {
        setDataChat(event.target.value, false);
    }

    const setDataChat = (message, _isFile) => {
        setValorTxt(message);
        var nombreParticipante = objSesion.nombreParticipant
        if (nombreParticipante == null || nombreParticipante == undefined) {
            nombreParticipante = objSesion.email;
        }
        const transmitterIdToString = participantId?.toString()
        setChat({
            isTransmisor: true,
            interviewId,
            receptorId,
            transmitterId: transmitterIdToString,
            statusId: 9,
            messageDate: new Date(),
            isGroup,
            isReceptorCandidate,
            isTransmitterCandidate: objSesion.isCandidate,
            message: message,
            status: 0,
            transmiterName: nombreParticipante,
            methodName: chanelId,
            isFile: _isFile
        })
    }

    const enviarMensaje = async () => {

        // selectFile(false);
        if (valorTxt !== null && valorTxt !== "" && valorTxt.length > 0) {
            try {
                setValorTxt('');
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/chat`;
                const fetchChat = HeaderPost(url, tokenVideo, chat);

                let res = await axiosExcecute(fetchChat);


                if (isFile) {
                    setLoadingFile(true)
                    enviarArchivo(res.data.id);
                }
            } catch (error) {
                message.error("Ocurrió un error al enviar un mensaje");
            }
        }
    }

    const enviarArchivo = async (chatId) => {

        try {
            let documentB64 = await encodeFileToBase64(file);
            var contenidoFile = documentB64.split(',')[1];
            if (contenidoFile !== null && contenidoFile !== undefined && contenidoFile.trim().length > 0) {
                let dataFile = {
                    "scheduleId": objSesion.scheduleId,
                    "documentContent": contenidoFile,
                    "fileName": file.name,
                    "participanId": objSesion.participantId.toString(),
                    "isCandidate": isCandidate,
                    "ChatName": chanelId,
                    "ShareFile": `file${objSesion.accessCode}`
                }
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/schedule/document`;
                const fetchChatDocument = HeaderPost(url, tokenVideo, dataFile);

                let res = await axiosExcecute(fetchChatDocument);

                saveDocumentChat(res.data.id, chatId)
            }
            else {
                message.error("No se puede enviar un archivo vacío");
            }

        } catch (error) {
            message.error("Ocurrió un error al enviar un documento en el chat");
        }
        setIsFile(false);
        setLoadingFile(false)

    }

    const saveDocumentChat = async (documentId, chatId) => {
        try {
            let data = {
                chatId,
                documentId,
                isCandidate,
                participantId: objSesion.participantId.toString(),
            }
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/chat/document`;
            const fetchDocumentInterview = HeaderPost(url, tokenVideo, data);

            let saveDocument = await axiosExcecute(fetchDocumentInterview);

        } catch (error) {
            message.error("Ocurrió un error al guardar un documento en el chat");
        }
    }

    const onKeyPress = (e) => {
        if (e.which === 13)
            enviarMensaje();
    }

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };


    const downloadFile = async (documentId, documentName) => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/document/downloader/${documentId}`;
            const fetchDocument = HeaderGetFile(url, tokenVideo);

            let res = await axiosExcecute(fetchDocument);

            if (res.status === 200) {
                const blob = new Blob([res.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            return res.status;
        } catch (error) {
            message.error("Ocurrió un error al descargar un documento en el chat");
            return null;
        }
    }

    const cancelFile = () => {
        setFile(null);
        setIsFile(false);
        setValorTxt('')
        setFileString('');
    }
    /**
     * Inicializa la conexion con los sockets
     */
    useEffect(() => {
        const options = {
            accessTokenFactory: () => {
                return GetTokenVideoEntrevista();
            }
        };
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${_SERVER}/hubs/chat`, options)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

    }, []);

    /**
     * Crea la conexión del socket con el id del canal
     * Se encuantra a la eschucha de un nuevo mensaje para porde almacenarlo en el arra de mensajes
     */
    useEffect(() => {
        if (connection) {
            connection.start().then(result => {
                connection.on(chanelId, message => {
                    message.messageDate = new Date();
                    setMensajes(m => [...m, message]);
                    updateNotification(message);
                });
            }).catch(e => {
                // message.error("Ocurrió un error al crear la conexión para el chat");
            });
        }
    }, [connection]);

    /**
     * obtiene el historial de los chat y los asigna al canal correcto
     */
    useEffect(() => {
        setMensajes(chatsPrevios.filter(chat => chat.methodName === chanelId));
    }, [chatsPrevios]);

    const updateNotification = (message) => {

        let listNotification = notifications;

        if (participantId === message.transmitterId) return;
        if (participantId === message.participanId) return;

        if (message.receptorId === null) {
            NotificationManager.info(message.transmiterName === undefined ? 'Le han enviado un archivo' : `${message.transmiterName} ha enviado un mensaje grupal`);
        }

        if (message.receptorId !== null || message.transmiterName === undefined) {
            NotificationManager.info(message.transmiterName === undefined ? 'Le han enviado un archivo' : `${message.transmiterName} ha enviado un mensaje`);
        }



        setNotification(listNotification); //<= aca lo ejecuto

    }

    /**
     * Mantiene el scroll hasta la parte inferior del contenedor del los mensajes
     */
    useEffect(scrollToBottom, [mensajesChat]);

    /**
     * Mostramos el formato de un mensaje
     * @param {*} _mensaje 
     */
    const Mensaje = (_mensaje) => {
        return (
            <div>
                {_mensaje.transmitterId !== undefined && !_mensaje.isFile && mensajeTexto(_mensaje)}
                {(_mensaje.transmitterId === undefined || _mensaje.isFile) && mensajeArchivo(_mensaje)}
                <ModalCompartirArchivo
                    show={modalShare}
                    onHide={() => setModalShare(false)}
                    url={urlFile} />
            </div>
        );
    }

    /**
     * Plantilla para mostrar un mensaje de tipo archivo
     * @param {*} _mensaje 
     * @returns 
     */
    const mensajeArchivo = (_mensaje) => {
        return (
            <section className={_mensaje.participanId === participantId ? 'd-flex justify-content-end' : 'd-flex justify-content-start'}>
                <Alert
                    className={_mensaje.participanId === participantId ? 'p-2 margin-chat-l alert-send' : 'p-2 margin-chat-r alert-reserved'}>
                    <Alert.Heading className="alert-chat w-100">
                        <Row  >
                            <Col sm={6}>{formatDate(_mensaje.messageDate)}</Col>
                            <Col sm={6} className="alert-chat__position-right">{_mensaje.participanId === participantId ? 'yo' : dataTrasmiter(_mensaje)}</Col>
                        </Row>
                    </Alert.Heading>
                    <div className={_mensaje.participanId === participantId ? 'font-medium-chat mt-1' : 'font-medium-chat mt-1'}>

                        <Row  >
                            <Col sm={12} style={{ maxWidth: '260px' }}>{_mensaje.documentDocumentName}</Col>
                            <Col sm={9} className="d-flex justify-content-end">
                                <Button variant="outline-primary" className="documento-compartido__bton-descarga mr-1" onClick={() => { setModalShare(true); setUrlFile(_mensaje.documentShortRoute) }}>
                                    <img src={shared} alt="" />
                                </Button>
                            </Col>
                            <Col sm={3} className="d-flex justify-content-end">
                                <Button variant="outline-primary" className="documento-compartido__bton-descarga mr-1" onClick={() => downloadFile(_mensaje.id, _mensaje.documentDocumentName)}>
                                    <img src={dowloand} alt="" />
                                </Button>
                            </Col>
                        </Row>

                    </div>
                </Alert >
            </section>
        )
    }

    /**
     * Plantilla para mostrar un mensaje de texto
     * @param {*} _mensaje 
     * @returns 
     */
    const mensajeTexto = (_mensaje) => {
        return (
            <section className={_mensaje.transmitterId === participantId ? 'd-flex justify-content-end' : 'd-flex justify-content-start'}>
                <Alert
                    className={_mensaje.transmitterId === participantId ? 'p-2 margin-chat-l alert-send' : 'p-2 margin-chat-r alert-reserved'}>
                    <Alert.Heading className="alert-chat w-100">
                        <Row  >
                            <Col sm={6}>{formatDate(_mensaje.messageDate)}</Col>
                            <Col sm={6} className="alert-chat__position-right">{_mensaje.transmitterId === participantId ? 'yo' : _mensaje.transmiterName?.split(" ")[0]}</Col>
                        </Row>
                    </Alert.Heading>
                    <div style={{ maxWidth: '260px' }} className={_mensaje.transmitterId === participantId ? 'font-medium-chat mt-1' : 'font-medium-chat mt-1 text-left'}>
                        {_mensaje.message}
                    </div>
                </Alert >
            </section>
        );
    }

    /**
     * Mostramos todos lo mensajes
     */
    return (
        <React.Fragment>
            <Row >
                <Col>
                    <div className="mt-2 MessageList">
                        {mensajesChat.map((mensaje, i) => (
                            <div key={i}>{Mensaje(mensaje)}</div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </Col>
            </Row>
            <SendMessage
                handleFileInput={handleFileInput}
                isFile={isFile}
                valorTxt={valorTxt}
                handleChange={handleChange}
                onKeyPress={onKeyPress}
                enviarMensaje={enviarMensaje}
                file={file}
                cancelFile={cancelFile}
                loadingFile={loadingFile}
                fileString={fileString}
            />
            {(isVisible) ? <p className="text-danger">{mensajeValidacion}</p> : null}
        </React.Fragment>
    );
}

/**
 * Componente para enviar mensajes y archivos en el chat
 */
const SendMessage = (props) => {

    const { handleChange, onKeyPress, enviarMensaje, isFile, handleFileInput, file, cancelFile, loadingFile, fileString } = props
    const inputFile = useRef(null);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    return (
        <React.Fragment>
            <Card className="border-0">
                <Card.Body className="pt-1 p-0 ">
                    <InputGroup className="font-large-chat">
                        {!isFile &&
                            <FormControl
                                placeholder="Escribe un mensaje"
                                aria-describedby="basic-addon2"
                                className="input-chat__boton--right "
                                name="mensajeTxt"
                                onChange={handleChange}
                                value={props.valorTxt}
                                onKeyPress={onKeyPress}
                                maxLength={255}
                            />
                        }
                        {isFile &&
                            <div className="chat-file p-1 form-control">
                                <p className="badge bg-secondary text-white mb-0 mt-0" style={{ width: "85%" }}  >{`${file.name}`}</p>
                                <FontAwesomeIcon icon={faTimesCircle} onClick={() => cancelFile()} className="text-secondary ml-2 mt-2 icono-accion-todos mr-2 " />
                            </div>
                        }
                        <InputGroup.Append className="input-chat">
                            <input type='file' id='file' value={fileString} onChange={handleFileInput} ref={inputFile} style={{ display: 'none' }}
                                accept={_FILE_VALID}
                            />
                            <Tooltip title={"Solo se aceptan las sig. extensiones " + _FILE_VALID} >
                                <Button variant="outline-primary" onClick={onButtonClick} className="input-chat__boton input-chat__boton--right ">
                                    <img src={fileImage} alt="" />
                                </Button>
                            </Tooltip>

                            {!loadingFile ?
                                <Button variant="outline-primary" className="input-chat__boton" onClick={enviarMensaje}>
                                    <img src={send} alt="" />
                                </Button> :
                                <Button variant="outline-primary" className="input-chat__boton" onClick={enviarMensaje}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            }
                        </InputGroup.Append>
                    </InputGroup>
                </Card.Body>
            </Card>
        </React.Fragment>
    )

}


export default Chat;