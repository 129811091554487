import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "./Header.css";
import logoBrive from "../images/logo-w.svg";
import logo from '../images/perfil-icon.jpg'
import Cookies from 'universal-cookie';
import { DeleteCookieSession } from '../../lib/cookieSession';
import { goToEntrevista } from '../../lib/Redireccion';
import { message } from 'antd';
const cookies = new Cookies();

function Header(props) {
    return (
        <React.Fragment>
            <Navbar className="header" sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand >
                    <img className="header_logo pl-4" src={logoBrive} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Navbar.Text>
                        <div onClick={goToEntrevista} className="secciones-header ">
                            <label className="ml-4 secciones-header--bold mb-0 cursor-point">Mis entrevistas</label>
                        </div>
                    </Navbar.Text>
                    <Navbar.Text>
                        <div className="secciones-header ml-4 mr-4">
                            <label className="secciones-header--bold mb-0">|</label>
                        </div>
                    </Navbar.Text>
                    <Navbar.Text>
                        <div className="secciones-header mr-2">
                            <label className="mb-0">{getUserName()}</label>
                        </div>
                    </Navbar.Text>
                    <Navbar.Text>
                        <img src={logo} className="icono-usuario" />
                    </Navbar.Text>
                    <Navbar.Text className="mr-5 " >
                        <NavDropdown drop="down" id="botonLogout" >
                            <NavDropdown.Item onClick={() => cerrarSesion(props)} ><div style={{ color: '#000' }}>Salir</div></NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Text>

                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    )
}

/**
 * Método que obtiene el nombre del usuario de la cookie de sesión
 * @returns string con el nombre del usuaro logueado
 */
function getUserName() {
    try {
        var nombreUsuario = "";
        if (cookies.get('auth') !== null && cookies.get('auth') !== undefined) {
            var auth = cookies.get('auth');
            var nombreUsuarioCookie = auth.user.name;
            if (nombreUsuarioCookie !== null && nombreUsuarioCookie !== undefined) {
               var palabras = nombreUsuarioCookie.split(" ");
                var total = palabras.length;
                if (total > 1) {
                    nombreUsuario = palabras[0] + " " + palabras[1];
                }
                else {
                    nombreUsuario = palabras[0]
                } 
            }
        }
        return nombreUsuario;
    } catch (error) {
        message.error("Ocurrió un error al obtener el nombre de un usuario");
        return "";
    }
}


async function cerrarSesion(props) {

    try {
        if (window != undefined && window.location != undefined  && !window.location.pathname.includes("iniciar/entrevista")) {
            DeleteCookieSession();   
        }
        else{
            var answer = window.confirm("Es posible que los cambios que implementaste no se puedan guardar. ¿Desea continuar?");
            if (answer) {
                cookies.set("cerrarSession", answer, { path: '/' });
                if (props.colgarLLamada) {
                    await props.colgarLLamada();
                }
                DeleteCookieSession();  
            }
        }
    } catch (error) {
        console.error("Error al cerrar la sesión");
        DeleteCookieSession();
    }
}

export default Header;
