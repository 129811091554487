export const _NUMXPAGE = 10;
export const _SERVER = 'https://interview.evaluatest.mx';
export const _SERVERBRIVE = 'https://apiempresas.evaluatest.com';
export const _NUM_MAX_TEXT = 1000;
export const _MB_MAX_FILES = 10; //100 MB
export const _SERVER_OPENVIDU = 'https://openvidu.evaluatest.mx';
export const _SECRET_OPENVIDU = "test123";
export const _MAX_MORE_MINUTES_INTERVIEW = 10;
export const _SERVER_GUIA_ENTREVISTA = 'https://interviewguide.evaluatest.mx';
export const _CAPTCHAT_KEY = '6LcH-SkaAAAAAO_j1BXfZDZa2wQX4104DVTjXgCa';
export const _SERVER_BRIVE_CANDIDATE = 'https://apitalento.evaluatest.com';
export const _IS_PRODUCCION = false;
export const _MIN_CREATE_SCHEDULE = 15;
export const _MAX_MIN_TIME_SCHEDULE = 120;
export const _MIN_MIN_TIME_SCHEDULE = 60;
export const _MIN_ESPERA_PARTICIPANTE = 10;
export const _MIN_SHOW_INDICADOR_TIME = 10;
export const _URL_EVALUATEST = 'https://empresas.evaluatest.com/empresa';
export const _FILE_VALID = '.doc,.docx,.pdf,.xls,.xlsx,.txt,.pptx,.ppt,.ppsx,.pps,.rar,.zip,.jpg,.jpeg,.png,.svg';
export const _APP_BASE_URL_EMPRESA = 'https://empresas.evaluatest.com';
export const _INTENTOS_RECONEXION = 3
export const _SERVER_TOOLTIPS = 'https://tooltips.evaluatest.mx';

export const _TOKEN = 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Imp1YW5jYXJsb3Muc2FuY2hlekB0ZWtzaS5teCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvZXhwaXJlZCI6IjQvMjkvMjAyMSA5OjE3OjM5IEFNIiwiSXNDYW5kaWRhdGUiOiJGYWxzZSIsImV4cCI6MTYxOTY4Nzg1OX0.E7BIKgahmIXEdCH-ebya-9h9Mt3XgCPX7La5MPw3x8o";

export const _HEADER = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': _TOKEN
    },
}


export const _HEADER_BRIVE = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + "n1M8xkHL6eNOxLWIORGI7CmVCWVlF4xkKQ5ub1hFRZSk4_EN-jG_Q_aMhNY2Kb3somnrwXbl4PJJdrho_Xnpo78MatZ-wHtZOa2bh-f6ti7jvtLX27Uvo-8W2h3SZmw2tEwNzia0hJvJZhrqQC0B80UVoLcnX_nSvfQgQtOWjrKgNwIcDXfsiTpyoXfiVj3k8zj_5BLD_LQjx2ySib7COA6kVhT1l2g-j1wXLVPTtMAudR2rPTIHAo3axkgmotmdm8T-GFvvRTYltt9jMernnv0nTZ2Pk7MQYvZ5SXVSpTiwxSWy9m5HJM0pYKvoxDGVAXnl1mac7vuuut50SHtLiak5R3acm7A1Eacy4IVWk06mCEF3gJHpDQE4QxbvpnYqSLvw6Zad6Pp-yjh6gp7DPdp4xnl962kEosk58fxZ-WPv3w7gDpFLzgAMwdKiCVUjuMOCUs-nm8JmvzrI9H7xsa8UPErAStQgU-ybnrlmvoczhbX96kL98mssk8x1qpaO9EBRJmDxa1nk73Qbsq2K5uZ3hOcnCAL6taStWXD5vd71eNvPoFQUYz_-wxNdcWUEUqPfOEshCTvbZAHmpbVIKw"
    },
}

export const _HEADERFILE = {
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': _TOKEN
    },
}

export const _HEADERGUIAENTREVISTA = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImdhYnJpZWwubW9udGFubzE3OTRAZ21haWwuY29tIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9leHBpcmVkIjoiMDYvMDQvMjAyMSAwODoyOTowMiBwLiBtLiIsImVudGVycHJpc2VJZCI6IjIxNCIsImV4cCI6MTYxNzc0MDk0Mn0.qcLn7yODCa8_btrOXnR4VhHaGzopFKAhSaLePC0BaNw"
    },
}