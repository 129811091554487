import { Button } from 'react-bootstrap';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const CargaArchivo = ({ loadFile }) => {
    const inputFile = useRef(null);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileInput = (e) => {
        loadFile(e.target.files[0])
    }

    return (
        <div>
            <input type='file' id='file' onChange={handleFileInput} ref={inputFile} style={{ display: 'none' }} />
            <Button variant="light" className="p-0" onClick={onButtonClick}>
                <label className="text-primary m-0">
                    <FontAwesomeIcon className="mr-2" size="sm" icon={faUpload} /> Subir archivos
                </label>
            </Button>
        </div>
    )
}

export default CargaArchivo