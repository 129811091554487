import React from 'react';
import { Icon } from 'antd';

const EventBusySVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M 9.31 17 l 2.44 -2.44 L 14.19 17 l 1.06 -1.06 l -2.44 -2.44 l 2.44 -2.44 L 14.19 10 l -2.44 2.44 L 9.31 10 l -1.06 1.06 l 2.44 2.44 l -2.44 2.44 L 9.31 17 Z M 19 3 h -1 V 1 h -2 v 2 H 8 V 1 H 6 v 2 H 5 c -1.11 0 -1.99 0.9 -1.99 2 L 3 19 c 0 1.1 0.89 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 16 H 5 V 8 h 14 v 11 Z"
    />
  </svg>
);

const EventBusy = props => <Icon component={EventBusySVG} {...props} />;

export default EventBusy;