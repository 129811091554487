import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import masParticipantes from "../../images/iconmonstr-user-25.svg";

function EsperaParticipantes(props) {
    return(
        <Card className="border-0 pt-0">
            <Card.Body className="pt-0">
                <Row>
                    <Col sm={12} className="mas-participantes">
                        <img className="mas-participantes__imagen mb-2" src={masParticipantes} alt=""/>
                        <div className="mas-participantes-integrantes">
                            <p className="mas-participantes-integrantes__text">En espera que los demás usuarios se unan a la llamada</p>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        
    );
}

export default EsperaParticipantes;