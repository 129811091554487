import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip, Modal, message } from 'antd';

import { EditIcon } from '../../Shared/Icons';

import { _APP_BASE_URL_EMPRESA } from '../../../settings';
import { interviewStatusTypes } from '../../../utils/types/interviewStatus';
import { redirectEntepriseTypes } from '../../../utils/types/redirect';
import { calcelInterview } from "../../../services/interview/cancel";
import "./style.css";

const Actions = ({ interview, userId, onCancelInterview }) => {
  const [ visible, setVisible ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ enabledCalcel, setEnabledCalcel ] = useState(false);
  const [ enabledEdit, setEnabledEdit ] = useState(false);

  useEffect(
    () => {
      const enabledActions = ![interviewStatusTypes.inProgress, interviewStatusTypes.finished].includes(interview.statusTypeId);
      setEnabledCalcel(enabledActions && (userId === interview.assesorId));
      setEnabledEdit(enabledActions && interview && interview.videoInterviewEnterpriseUrl);
    },
    [interview.statusTypeId]
  );

  const handleOpenConfirmModal = () => {
    setVisible(true);
  }

  const handleCloseConfirmModal = () => {
    setVisible(false);
    setLoading(false);
    
  }

  const handleCancelMeeting = async () => {
    setLoading(true);
    try {
      const response = await calcelInterview({
        accessCode: interview.accessCode
      });
      if (response.status === 200) {
        onCancelInterview(interview.id);
      } else {
        throw new Error('Error al cancelar entrevista');
      }
    } catch (error) {
      message.error('Error al cancelar la entrevista');
      setLoading(false);
    }
  }

  const handleEdit = () => {
    window.open(`${_APP_BASE_URL_EMPRESA}?${redirectEntepriseTypes.editInterview}=${interview.videoInterviewEnterpriseUrl}`, '_blank');
  }

  return (
    <div key={`actions-${interview.id}`}>
      <Tooltip
        title={enabledEdit ? 'Editar entrevista' : null}
        placement="left"
      >
        <button
          className="evt-btn-icon mr-1"
          onClick={handleEdit}
          disabled={!enabledEdit}
        >
          <EditIcon />
        </button>
      </Tooltip>
      <Tooltip
        title={enabledCalcel ? 'Cancelar entrevista' : null}
        placement="right"
      >
        <button
          className="evt-btn-icon ml-1"
          onClick={handleOpenConfirmModal}
          disabled={!enabledCalcel}
        >
          <Icon type="close-circle" theme="filled" />
        </button>
      </Tooltip>
      {
        visible
          &&
        <Modal
          key={`evt-modal-confirm-${interview.id}`}
          className="evt-modal-confirm"
          title="Cancelar entrevista"
          visible={visible}
          onOk={handleCancelMeeting}
          okButtonProps={{ loading, size: 'large' }}
          cancelButtonProps={{ size: 'large' }}
          onCancel={handleCloseConfirmModal}
          okText="Sí, cancelar"
          cancelText="No cancelar"
          closable={false}
          maskClosable={false}
          maskStyle={{ backgroundColor: '#00205B66', backdropFilter: 'blur(5px)' }}
        >
          <p>¿Estás seguro de que quieres cancelar esta entrevista?</p>
        </Modal>
      }
    </div>
  );
};

Actions.propTypes = {
  
};

export default Actions;