import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Editor } from 'react-draft-wysiwyg';
import { _NUM_MAX_TEXT } from "../../settings";
import './texto.css';

import bold from "../../../src/images/richIcon/bold.svg";
import italic from "../../../src/images/richIcon/italic.svg";
import underline from "../../../src/images/richIcon/under.svg";

class TextoEnrriquecido extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: null,
        }
    }
    render() {
        return (
            <div className="main-richtext">
                <Editor
                    placeholder="Escriba un texto"
                    editorState={this.props.respuesta}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={this.props.onEditorStateChange}
                    onContentStateChange={this.props.onContentStateChange}
                    handleBeforeInput={val => {

                        console.log("Detectando cambios al escribir.....");
                        if (this.props.onShowIndicator) {
                            this.props.onShowIndicator();
                        }
                        const textLength = this.props.respuesta.getCurrentContent().getPlainText().length;
                        if (val && textLength >= _NUM_MAX_TEXT) {
                            return 'handled';
                        }
                        return 'not-handled';
                    }}
                    handlePastedText={val => {
                        const textLength = this.props.respuesta.getCurrentContent().getPlainText().length;
                        return ((val.length + textLength) >= _NUM_MAX_TEXT);
                    }}
                    toolbar={{
                        inline: {
                            inDropdown: false, options: ['bold', 'italic', 'underline'],
                            bold: { icon: bold, className: "sin-borde demo-option-custom" },
                            italic: { icon: italic, className: "sin-borde demo-option-custom" },
                            underline: { icon: underline, className: "sin-borde demo-option-custom" },
                        },
                        list: { inDropdown: false, options: [], },
                        textAlign: { inDropdown: false, options: [] },
                        link: { inDropdown: false, options: [] },
                        history: { inDropdown: false, options: [] },
                        fontFamily: { className: "ocultar", options: [] },
                        blockType: { inDropdown: false, className: "ocultar", options: [] },
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: "font-sizelist",
                        },
                        colorPicker: { inDropdown: false, className: "ocultar", options: [] },
                        emoji: { inDropdown: false, className: "ocultar", emojis: [] },
                        embedded: { inDropdown: false, className: "ocultar", options: [] },
                        image: { inDropdown: false, className: "ocultar", options: [] },
                        remove: { className: "ocultar" },
                    }}
                />
                <Row>
                    <Col sm={12} className="label-contador">
                        <label className="mr-1 mb-0" style={{ color: "lightgrey" }}>
                            {this.props.respuesta !== undefined ? this.props.respuesta.getCurrentContent().getPlainText().length : 0}/{_NUM_MAX_TEXT}
                        </label>
                    </Col>
                </Row>
            </div>

        )
    }
}

export default TextoEnrriquecido;