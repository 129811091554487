import React from 'react';
import { Card, Container, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { _URL_EVALUATEST } from '../../settings';

class ComponenteConfirmacion extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        mensaje: this.props.mensaje,
        imagen: this.props.imagen,
    }

    goToEvaluates(){
        var urlPosulaciones =_URL_EVALUATEST+"?nombre="+this.props.nombreEmpresa;
        window.open(urlPosulaciones,"_target");
    }

    render() {
        const { mensaje, imagen } = this.state
        return (
            <div className="mt-5 pt-2">
                <section className=" contendor-confirmacion-rechazo container d-flex align-items-center">
                    <div className="w-100">
                        <div className="row ">
                            <img className="pt-2 contendor-confirmacion__imagen mx-auto d-block" src={this.props.imagen} />
                        </div>

                        {this.props.mensaje === 'Cargando...' ? <div className="row">
                            <div className="col-md-12 center ">
                                <div className="col-md-12 center titulo-posponer__principal">
                                    <Spinner animation="border" variant="primary" /> <label >{'  ' + this.props.mensaje}</label> 
                                </div>
                            </div>
                        </div> : <div className="row titulo-posponer">
                            <div className="col-md-12 center titulo-posponer__principal">
                                <label >{this.props.isError ? "¡Error!" : "¡Excelente!"}</label>
                            </div>
                            <div className="col-md-12 center titulo-posponer__subtitulo">
                                <label >{this.props.mensaje}</label>
                            </div>
                        </div>}

                        {(this.props.nombreEmpresa !== null && this.props.nombreEmpresa !== undefined) ?
                            <Row className="justify-content-md-center mt-4">
                                <Col md={11} className="mt-2 alinear-centro">
                                <button className="btn btn-block btn-primary mb-4 boton-posponer boton-posponer__titulo" type="button" onClick={() => this.goToEvaluates()} disabled={this.props.habilitarAccion} >
                                        Ver otras vacantes
                                    </button>
                                </Col>
                            </Row> : null
                        }
                    </div>
                </section>
            </div>


        )
    }
}

export default ComponenteConfirmacion;