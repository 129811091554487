import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'antd';

import { GetAuth, DeleteCookieSession, SaveCookeSession } from '../../../lib/cookieSession';
import { dateToUTC, sleep } from '../helpers';
import { getInterviewerRefreshToken, getCandidateRefreshToken } from '../services';

let timer = null
let interval = null

export const RefreshTokenContext= createContext()

export const RefreshTokenProvider = ({ children }) => {
  const [hasAuth, setHasAuth] = useState(false)
  const [timeOutMS, setTimeOutMS] = useState(null)
  const [showExpiresModal, setShowExpiresModal] = useState(false)

  useEffect(() => {
    if (!hasAuth) {
      interval = setInterval(() => {
        getAuth()
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [hasAuth]);

  useEffect(() => {
    if (hasAuth) {
      if (timeOutMS > 0) {
        timer = setTimeout(() => {
          onRefreshToken();
        }, timeOutMS);
      } else {
        clearTimeout(timer);
        onRefreshToken();
      }
    }
    return () => clearTimeout(timer);
  }, [timeOutMS, hasAuth]);

  const getAuth = useCallback(
    async () => {
      const auth = GetAuth();
      if (!!auth?.date_expiry) {
        setHasAuth(true)
        const substractedMiliseconds = 120000;
        // Obtenemos fecha actual
        const currentDateUTC = dateToUTC({
          date: new Date(),
        });
        // Obtenefos fecha de expiracion
        const expiresDateUTC = dateToUTC({
          date: new Date(auth.date_expiry),
          substracted: substractedMiliseconds
        })
        // Diferencia para milisegundos
        const difference = expiresDateUTC - currentDateUTC;
        setTimeOutMS(difference);
      } else {
        clearTimeout(timer);
        setTimeOutMS(null);
      }
    },
    [timer],
  )

  const onRefreshToken = useCallback(
    async () => {
      const maxTries = 6;
      let retry = false;
      let tries = 0;
      let sleepDelay = 1000;

      do {
        if(tries > maxTries) {
          setShowExpiresModal(true);
          retry = false;
          return;
        } 
        try {
          const { refres_toke, isInterviewer } = GetAuth();
          if (isInterviewer) {
            const result = await getInterviewerRefreshToken({ refresh_token: refres_toke })
            if (result?.data) {
              const auth = GetAuth();
              const updated = {
                ...auth,
                tokenBrive: result.data.access_token,
                tokenVE: result.data.access_token,
                tokenGuia: result.data.access_token,
                refres_toke: result.data.refresh_token,
                date_expiry: result.data['.expires'],
              }
              SaveCookeSession(updated);
              setHasAuth(false)
            } throw new Error('Error al obtener sesión')
          } else {
            const result = await getCandidateRefreshToken({ refresh_token: refres_toke })
            if (result?.data) {
              const auth = GetAuth();
              const updated = {
                ...auth,
                tokenVE: result.data.access_token,
                refres_toke: result.data.refresh_token,
                date_expiry: result.data['.expires'],
              }
              SaveCookeSession(updated);
              setHasAuth(false)
            } else throw new Error('Error al obtener sesión')
          }
        } catch (error) {
          console.error(error);
          retry = true;
          tries += 1;
          await sleep(sleepDelay);
          sleepDelay += 1000;
        }
      } while (retry && tries <= maxTries);
    },
    [],
  )

  const onLogOut = useCallback(
    () => {
      setShowExpiresModal(false)
      DeleteCookieSession()
    },
    [],
  )
  

  return (
    <RefreshTokenContext.Provider
      value={{}}
    >
      <Modal
        title="Tu sesión ha expirado"
        visible={showExpiresModal}
        closable={false}
        footer={null}
      >
        <p>Debes volver a iniciar tu sesión desde la página de inicio.</p>
        <div class="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={onLogOut}
          >
            Salir
          </Button>
        </div>
      </Modal>
      { children }
    </RefreshTokenContext.Provider>
  )
}