import { Alert } from 'antd';

const defaultMessage = 'El texto contiene etiquetas HTML no validas'

export const AlertDangerousText = ({ secure, message = defaultMessage, ...props }) => {
  if (secure) return null
  
  return (
    <Alert
        type="error"
        message={message}
        {...props}
    />
  )
}