import React from"react";
import UserVideoComponent from './UserVideoComponent';
import axios from 'axios';
import { OpenVidu } from 'openvidu-browser';

import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import masParticipantes from "../images/iconmonstr-user-25.svg";
// importamos as imagenes
import chat from "../images/chat.svg";
import compartirPantalla from "../images/screen_share.svg";
import grabar from "../images/record.svg";
import finLlamada from "../images/colgar.svg";
import finLlamadaDisabled from "../images/colgarDisabled.svg";
import dejarGrabr from "../images/dejar-grabar.svg";
import dejarCompartir from "../images/dejar-compartir.svg";
import apagarCamara from "../images/apagarCamara.svg";
import silenciar from "../images/silenciar.svg";
import logoCamara from "../images/logoCamara.svg";
import logoMicrofono from "../images/mic.svg";

import { _SERVER_OPENVIDU, _SECRET_OPENVIDU, _MAX_MORE_MINUTES_INTERVIEW, _MIN_ESPERA_PARTICIPANTE, _MIN_SHOW_INDICADOR_TIME, _INTENTOS_RECONEXION } from "../../settings";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, Popover, Icon } from 'antd';

class VideoParticipante extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mySessionId: props.objSession.accessCode,
            myUserName: props.objSession.nombreParticipant,
            isCandidate: props.objSession.isCandidate,
            myUserId: props.objSession.participantId,
            avatar:props.objSession.urlAvatar, 
            nombrePuesto: props.objSession.jobProfileName,
            nombreCandidato: props.objSession.nombreCandidato,
            asuntoEntrevista: props.objSession.subject,
            session: undefined,
            mainStreamManager: undefined,
            streamCandidato: undefined,
            publisher: undefined,
            subscribers: [],
            totalParticipantes:[],
            salaId: props.objSession.accessCode,
            recordId: null,
            styleRejilla: "row-cols-2",
            isRecording: false,
            isSharedScreen: false,
            durationTime:{
                hours:0,
                minutes:0,
                seconds:0
            },
            restantTime:{
                minutes:0,
                seconds:0
            },
            seconds: 0,
            endsSecons:0,
            idClock: null,
            showSolicitarTiempo: props.isMoreTime,
            showLessTime: false,
            hasAudio: true,
            hasVideo: true,
            isRecordProcess: false,
            isEsperaParticipantes:true,
            endTime: undefined,
            estatusConexion: 0,
            intentosReconexion: 0,
            intervaloReconexionId: undefined,
            isFinaliced: props.isFinaliced
        };

        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.shareScreen = this.shareScreen.bind(this);
        this.leaveShareScreen = this.leaveShareScreen.bind(this);
        this.verificateUserSharedScreen = this.verificateUserSharedScreen.bind(this);
        this.verificateParticipantSharedScreen = this.verificateParticipantSharedScreen.bind(this);
        this.startRecord = this.startRecord.bind(this);
        this.leaveRecording = this.leaveRecording.bind(this);
        this.addParticipant = this.addParticipant.bind(this);
        this.removeParticipant = this.removeParticipant.bind(this);
        this.addAllParticipantStartSession = this.addAllParticipantStartSession.bind(this);
        this.verificateLeaveSessionIsScreen = this.verificateLeaveSessionIsScreen.bind(this);
        this.verificateTotalParticipants = this.verificateTotalParticipants.bind(this);
        this.identificarCandidato = this.identificarCandidato.bind(this);
        this.verificateIfCandidateleave = this.verificateIfCandidateleave.bind(this);
        this.temporalizate = this.temporalizate.bind(this);
        this.startDates = this.startDates.bind(this);
        this.clearIntervaloTime = this.clearIntervaloTime.bind(this);
        this.muteOrUnMuterAudio = this.muteOrUnMuterAudio.bind(this);
        this.muteOrUnMuterVideo = this.muteOrUnMuterVideo.bind(this);
        this.getListOfRecording = this.getListOfRecording.bind(this);
        this.reconexionOpenVidu = this.reconexionOpenVidu.bind(this);
    }


    componentDidMount() {
        this.startDates();
        window.addEventListener('beforeunload', this.onbeforeunload);
        this.joinSession();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    handleChangeSessionId(e) {
        this.setState({
            mySessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            myUserName: e.target.value,
        });
    }

    handleMainVideoStream(stream) {
        if (this.state.mainStreamManager !== stream) {
            this.setState({
                mainStreamManager: stream
            });
        }
    }

    deleteSubscriber(streamManager) {
        try {
            let subscribers = this.state.subscribers;
            let index = subscribers.indexOf(streamManager, 0);
            if (index > -1) {
                subscribers.splice(index, 1);
                this.setState({
                    subscribers: subscribers,
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al remover un participante de la sesión");
        }
    }

    convertUTCDateToLocalDate (date) {
        date = new Date(date);
        var localOffset = date.getTimezoneOffset() * 60000;
        var localTime = date.getTime();
        date = localTime + localOffset;
        date = new Date(date);
        return date;   
    }

    startDates(){
        try {
            // verificamos si existe algun indicador de tiempo en ejecucion, si existe lo detenemos
            if (this.state.idClock !== null) {
                this.clearIntervaloTime();
            }

            // iniciamos las horas
            var startTime = new Date(this.props.startTime);
            startTime.setSeconds(0);
            var endTime = new Date(this.props.endTime);
            endTime.setSeconds(0);

            if (this.props.isMoreTime) {
                endTime.setMinutes(endTime.getMinutes() + _MAX_MORE_MINUTES_INTERVIEW);
                this.props.clearIntervalo();
            }
            var currentDate = new Date();

            // calculamos la duracion de la entrevista en segundos
            var durationInterviewML = endTime.getTime() - startTime.getTime();
            var durationInterviewSEC = Math.trunc(durationInterviewML / 1000);
            // calculamos cuanto tiampo ha pasado desde que se inicio la entrevista
            var timeTrascurridoML = currentDate.getTime() - startTime.getTime();
            var timeTrancurridoSEC = 0;
            if (timeTrascurridoML > 0) {
                timeTrancurridoSEC = Math.trunc(timeTrascurridoML / 1000);
            }

            this.setState({
                seconds: timeTrancurridoSEC,
                endsSecons:durationInterviewSEC,
            });

            let timeId = setInterval(() => {
                this.temporalizate();
            }, 1000);

            this.setState({
                idClock: timeId,
                endTime : endTime,
            });
        } catch (error) {  
            message.error("Ocurrió un error al inicializar el temporalizador");
        }
    }

    clearIntervaloTime(){
        try {
            let idIntervalo = this.state.idClock;
            clearInterval(idIntervalo);

            this.setState({
                idClock: null
            })
        } catch (error) {
            message.error("Ocurrió un error al detener el temporalizador");
        }
    }

    temporalizate(){
        try {
            // nuevo
            // iniciamos las horas
            var startTime = new Date(this.props.startTime);
            startTime.setSeconds(0);           
            var currentDate = new Date();

            // calculamos cuanto tiempo ha pasado desde que se inicio la entrevista
            var timeTrascurridoML = currentDate.getTime() - startTime.getTime();
            var timeTrancurridoSEC = 0;
            if (timeTrascurridoML > 0) {
                timeTrancurridoSEC = Math.trunc(timeTrascurridoML / 1000);
            }
            
            var seconds = timeTrancurridoSEC;
            var limitTimeEnd = this.state.endsSecons - (60 * _MIN_SHOW_INDICADOR_TIME);

            var hour = Math.floor(seconds / 3600);
            hour = (hour < 10) ? '0'+hour  : hour;
            var minute = Math.floor((seconds / 60) % 60);
            minute = (minute < 10) ? '0'+minute : minute;
            var second = seconds % 60;
            second = (second < 10) ? '0'+second : second;

            var restantMinutes = 0;
            var restantSeconds = 0;

            var showLessTime = false;
            if (seconds >= limitTimeEnd) {
                showLessTime = true
                var restantSecondsInterview = this.state.endsSecons - seconds;
                restantMinutes = Math.floor((restantSecondsInterview / 60) % 60);
                restantMinutes = (restantMinutes < 10) ? '0'+restantMinutes : restantMinutes;
                restantSeconds = restantSecondsInterview % 60;
                restantSeconds = (restantSeconds < 10) ? '0'+restantSeconds : restantSeconds;

                if (!this.state.showSolicitarTiempo && this.props.idIntervaloMoreTime === null) {
                    this.props.startIntervalo();
                }
            }

            this.setState({
                durationTime:{
                    hours: hour,
                    minutes: minute,
                    seconds: second
                },
                restantTime:{
                    minutes:restantMinutes,
                    seconds:restantSeconds
                },
                seconds : seconds,
                showLessTime: showLessTime
            });
            // verificamos si se esta esperando a demas participnates
            if (this.state.isEsperaParticipantes) {
                var endTimeRegla = new Date(this.state.endTime);
                // restamos los minutos de espera de participantes
                endTimeRegla.setMinutes(endTimeRegla.getMinutes() - _MIN_ESPERA_PARTICIPANTE);
            }
            
            // identificamos si se ha concluido el tiempo de la entrevista
            if(this.state.isFinaliced !== 3){
                if (seconds >= this.state.endsSecons) {
                    this.leaveSession();
                }

                if (!this.state.showSolicitarTiempo && this.props.isMoreTime) {
                    this.setState({
                        showSolicitarTiempo: true,
                    });
                    this.startDates();
                } 
            } else {
                this.setState({
                    showSolicitarTiempo: false,
                    showLessTime: false
                });
            }

        } catch (error) {
            message.error("Ocurrió un error al actualizar el temporalizador");
        }
    }

    getListOfRecording(){
        try {
            axios.get(_SERVER_OPENVIDU + '/api/recordings', {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + _SECRET_OPENVIDU),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    var sesionId = this.state.salaId;
                    if (response.data !== null && response.data !== undefined && response.data.items !== null && response.data.items !== undefined) {
                        // fitramos solo las sesiones que estan activas 
                        var listaFiltrada = response.data.items.filter(grabacion => grabacion.sessionId === sesionId && grabacion.status === "started");
                        if (listaFiltrada.length > 0) {
                            // identificamos si nuestro usuario logueado es el que esta grabando
                            var userIsRecordin = false;
                            // verificamos si existe una grabación en curso.
                            var grabacionIdExiste = localStorage.getItem("idGrabacionIniciada");
                            if (grabacionIdExiste !== null && grabacionIdExiste !== undefined && grabacionIdExiste !== "") {
                                // verificamos que el id de la grabacion almacenada en la variable global sea la misma de la grabacion en curso
                                if (grabacionIdExiste === listaFiltrada[0].id) {
                                    userIsRecordin = true;
                                }
                            }

                            this.setState({
                                recordId : listaFiltrada[0].id,
                                isRecordProcess: true,
                                isRecording: userIsRecordin
                            })
                        }
                    }
                })
                .catch((error) => {
                    message.error("Ocurrió un error al obtener la lista de grabaciones activas de la sesión");
                });

        } catch (error) {
            message.error("Ocurrió un error al obtener la lista de grabaciones activas de la sesión");
        }
    }

    removeAccents = (str) => {
        try {
            return str.replace(/[^a-zA-Z0-9 -]/g, '');
        } catch (error) {
            return str;
        }
    }

    startRecord(){
        try {
            var asuntoEntrevista = this.state.asuntoEntrevista;
            var nombreGrabacion = asuntoEntrevista.replace(/[/ ]/g, '-') + "-" + this.state.nombreCandidato.replace(/[/ ]/g, '-');
            // removemos los acentos
            nombreGrabacion = this.removeAccents(nombreGrabacion);
            let recordFormat = {
                "session":this.state.salaId,
                "name": nombreGrabacion,
                "outputMode":"COMPOSED",
                "hasAudio": true,
                "hasVideo": true,
                "recordingLayout":"CUSTOM",
                "customLayout":"customer-video",
                "resolution": "1280x720"
            }

            axios.post(_SERVER_OPENVIDU + '/api/recordings/start', recordFormat, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + _SECRET_OPENVIDU),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200 && response.data !== null) {
                        // creamos la variable global para guardar el id de la grabación en turno
                        localStorage.setItem("idGrabacionIniciada",response.data.id);
                        this.setState({
                            recordId : response.data.id,
                            isRecording: true,
                        })
                    }
                })
                .catch((error) => {
                    // si la grabacion ya esta iniciada
                    if (error.response.status === 409) {
                    }
                    else{
                        NotificationManager.error("Error al iniciar la grabación");
                    }
                });

        } catch (error) {
            message.error("Ocurrió un error al iniciar la grabación de la sesión");
        }
    }

    leaveRecording(){
        try {
            this.props.saveVideo(this.state.recordId);
        } catch (error) {  
            message.error("Ocurrió un error al detener la grabación de la sesión");
        }
    }


    muteOrUnMuterAudio(valor){
        try {
            var publisher = this.state.publisher;
            publisher.publishAudio(valor); 

            this.setState({
                hasAudio: valor,
                publisher
            });

        } catch (error) {
            var mensaje = "silenciar al participante"
            if (valor) {
                mensaje = "habilitar el audio del participante";
            }
            message.error("Ocurrió un error al "+mensaje);
        }
    }

    muteOrUnMuterVideo(valor){
        try {
            if (!this.state.isSharedScreen) {
                var publisher = this.state.publisher;
                publisher.publishVideo(valor); 
                publisher.publishAudio(this.state.hasAudio);
                this.setState({
                    hasVideo: valor,
                    publisher
                });
            }
        } catch (error) {
            var mensaje = "quitar el video del participante"
            if (valor) {
                mensaje = "habilitar el video del participante";
            }
            message.error("Ocurrió un error al "+mensaje);
        } 
    }

    shareScreen(){
        try {
            var sessionScreen = this.state.session;

            let publisher = this.OV.initPublisher(undefined, {
                audioSource: undefined, 
                videoSource: "screen", 
                publishAudio: this.state.hasAudio, 
                publishVideo: true, 
                resolution: '1280x720', 
                frameRate: 30, 
                insertMode: 'APPEND', 
                mirror: false, 
            });
            
            publisher.once('accessAllowed', (event) => {
                // quitamos el video de la camara Web
                sessionScreen.unpublish(this.state.publisher);

                publisher.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
                    this.leaveShareScreen();
                });
                // publicamos el nuevo video compartiendo la pantalla
                sessionScreen.publish(publisher);
                
                this.setState({
                    publisher: publisher,
                    isSharedScreen : true
                });
            });

            publisher.once('accessDenied', (event) => {
            });
        } catch (error) {  
            message.error("Ocurrió un error al compartir la pantalla"); 
        }  
    }

    leaveShareScreen(){
        try {
            var sessionScreen = this.state.session;

            let publisher = this.OV.initPublisher(undefined, {
                audioSource: undefined, 
                videoSource: undefined, 
                publishAudio: this.state.hasAudio, 
                publishVideo: this.state.hasVideo, 
                resolution: '1280x720', 
                frameRate: 30, 
                insertMode: 'APPEND', 
                mirror: false, 
            });
            // quitamos el video de la pantalla
            sessionScreen.unpublish(this.state.publisher);

            // publicamos el nuevo video de la webCam
            sessionScreen.publish(publisher);
            // actualizamos nuestros state
            this.setState({
                publisher: publisher,
                isSharedScreen : false
            });
        } catch (error) {
            message.error("Ocurrió un error al dejar de compartir la pantalla"); 
        }
    }

    joinSession() {
        // --- 1) Creamos un objeto OpenVidu ---

        this.OV = new OpenVidu();

        // --- 2) Iniciamos la sesión ---

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                var mySession = this.state.session;

                // --- 3) Activamos los oyentes ---

                // oyente que detecta cuando un participante se unio a la sesión
                mySession.on('streamCreated', (event) => {
                    var subscriber = mySession.subscribe(event.stream, undefined);

                    var participantId = JSON.parse(event.stream.connection.data).clientId;
                    var isCandidate = JSON.parse(event.stream.connection.data).isCandidate;

                    var subscribers = this.state.subscribers;
                    // verificamos is existe el participante
                    var suscriberExist = subscribers.filter(streamManager => JSON.parse(streamManager.stream.connection.data).clientId ===  participantId);
                    if (suscriberExist.length === 0) {
                        // agregamos al participante que se unio a la sala
                        this.addParticipant(participantId, isCandidate);
                        // agregamos el video del participante que se acaba de unir
                        subscribers.push(subscriber);

                        // actualizamos los state
                        this.setState({
                            subscribers: subscribers
                        });
                        this.verificateUserSharedScreen(subscriber);
                    }
                });

                // oyente de detecta el participante que salio de la sesión
                mySession.on('streamDestroyed', (event) => {
                    // Removemos el video del participante que salio
                    this.deleteSubscriber(event.stream.streamManager);
                    // identificamos los datos del articipante que dejo la sesion
                    var participantId = JSON.parse(event.stream.connection.data).clientId;
                    var isCandidate = JSON.parse(event.stream.connection.data).isCandidate;
                    // identificamos si el participnate salio definitivamente de la sesion
                    if (event.reason === "disconnect" || event.reason === "networkDisconnect") {
                        
                        // removemos el participante que salio de la lista
                        this.removeParticipant(participantId, isCandidate);
                        this.verificateTotalParticipants();
                    }
                    if (isCandidate) {
                        this.setState({
                            streamCandidato : undefined
                        });
                    }
                    // identificamos si el candidato deja la reunion
                    this.verificateIfCandidateleave(isCandidate);
                    // verificamos si el que salio estaba compartiendo pantella y la removemos
                    this.verificateLeaveSessionIsScreen(event);
                });

                mySession.on('recordingStarted', (event) => {
                    this.setState({
                        recordId: event.id,
                        isRecordProcess: true
                    });
                });

                mySession.on('recordingStopped', (event) => {
                    this.setState({
                        isRecording: false,
                        isRecordProcess : false,
                        recordId: null,
                    });
                    NotificationManager.info("La grabación de la sesión finalizo");

                    var grabacionIdExiste = localStorage.getItem("idGrabacionIniciada");
                    if (grabacionIdExiste !== null && grabacionIdExiste !== undefined && grabacionIdExiste !== "") {
                        // removemos la variable global del id de la grabación
                        localStorage.removeItem("idGrabacionIniciada");
                    }
                });

                    mySession.on('sessionDisconnected', (event) => {
                        // identificamos los datos del articipante que dejo la sesion
                        var participantId = JSON.parse(event.target.connection.data).clientId;
                        var isCandidate = JSON.parse(event.target.connection.data).isCandidate;
                        if (event.reason === 'networkDisconnect') {
                            // identificamos si el participnate salio definitivamente de la sesion
                            // removemos el participante que salio de la lista
                            this.removeParticipant(participantId, isCandidate);
                            this.verificateTotalParticipants();
                            
                            if (isCandidate) {
                                this.setState({
                                    streamCandidato : undefined
                                });
                            }

                            // identificamos si el candidato deja la reunion
                            this.verificateIfCandidateleave(isCandidate);

                            var myVideo = this.state.publisher;
                            var userIdLoguado = JSON.parse(myVideo.stream.connection.data).clientId;

                            if (userIdLoguado === participantId) {

                                this.setState({
                                    estatusConexion: 0,
                                    intentosReconexion: 0
                                });

                                message.error("La sesión se desconecto por problemas de red, volviendo a conectar");
                                // primero removemos la sesion previa
                                this.leaveSession()
                                // tratamos de hacer la reconexión
                                let timeId = setInterval(() => {
                                    this.reconexionOpenVidu();
                                }, 2000);

                                this.setState({
                                    intervaloReconexionId: timeId
                                })
                            }
                        }
                    });

                mySession.on('reconnecting', () => {
                    message.info("Problemas de red, Intentando reconectar la sesión");
                });

                mySession.on('reconnected', () => {
                    message.success("La sesión se reestablecio");
                });

                // --- 4) Conectamos la sesión con el user token ---

                this.getToken().then((token) => {
                    
                    mySession
                        .connect(
                            token,
                            { clientData: this.state.myUserName, 
                                isCandidate: this.state.isCandidate,
                                clientId: this.state.myUserId,
                                avatar: this.state.avatar},
                        )
                        .then(() => {
                            // verificamos si hay una grabacion activa de la sesion
                            this.getListOfRecording();
                            // verificamos que al cargar la vista no haya participantes compartiendo pantalla
                            this.verificateParticipantSharedScreen();
                            // cargamos todos los participnates al iniciar la vista en caso de que ya hayan participantes al momento de unirse
                            this.addAllParticipantStartSession();
                            // agregamos nuestra sesion a la lista de participnates
                            this.addParticipant(this.state.myUserId, this.state.isCandidate);
                            // --- 5) Creamos nuestro video para mostrar ---

                            let publisher = this.OV.initPublisher(undefined, {
                                audioSource: undefined, 
                                videoSource: undefined, 
                                publishAudio: true, 
                                publishVideo: true, 
                                resolution: '1280x720', 
                                frameRate: 30, 
                                insertMode: 'APPEND', 
                                mirror: false, 
                            });

                            // --- 6) Publicamos el video ---

                            mySession.publish(publisher);
                            // agregamos al participante que se unio a la sala
                            this.addParticipant(this.state.myUserId, this.state.isCandidate);
                            // Set the main video in the page to display our webcam and store our Publisher
                            this.setState({
                                publisher: publisher,
                                estatusConexion: 1,
                                intentosReconexion: 0
                            });
                        })
                        .catch((error) => {
                        });
                });
            },
        );
    }

    reconexionOpenVidu(){
        try {
            var intentosReconexion = this.state.intentosReconexion + 1;

            if (this.state.estatusConexion === 0) {
                if (intentosReconexion < _INTENTOS_RECONEXION) {
                    if (this.state.totalParticipantes.length > 0) {
                        this.setState({
                            estatusConexion : 2 //esta en proceso
                        })
                        this.joinSession();
                    }

                    this.setState({
                        intentosReconexion : intentosReconexion
                    })
                }
                else{
                    message.error("Se excedió el número de intentos para reconectar la sesión, por favor recargue la página");
                }
            }
            else if (this.state.estatusConexion === 1)
            {
                // limpiamos el intervalo de tiempo
                let idIntervalo = this.state.intervaloReconexionId;
                clearInterval(idIntervalo);
            }
        } catch (error) {
            message.error("Ocurrió un error al intentar reconectar la sesión");
            this.setState({
                intentosReconexion : 0,
                estatusConexion: 0
            })

            // limpiamos el intervalo de tiempo
            let idIntervalo = this.state.intervaloReconexionId;
            clearInterval(idIntervalo);

        }
    }

    addParticipant(participantId, isCandidate)
    {
        try {
            this.props.addParticipant(participantId, isCandidate);
            var participante = {
                participanteId: participantId,
                isCandidate : isCandidate
            }

            var participantesAlmacenados = this.state.totalParticipantes;
            // verificamos que el participante no este ya agregado a la lista
            var filterParticipant = participantesAlmacenados.filter(participanteL => participanteL.participanteId === participantId);
            if (filterParticipant.length === 0) {
                participantesAlmacenados.push(participante);
            }
            var isEsperaParticipantes = true;
            if (participantesAlmacenados.length > 1) {
                isEsperaParticipantes = false;
            }
            this.setState({
                totalParticipantes : participantesAlmacenados,
                isEsperaParticipantes: isEsperaParticipantes,
            });
        } catch (error) {
            message.error("Ocurrió un error al agregar un nuevo participante a la sesión"); 
        }
    }

    removeParticipant(participantId, isCandidate)
    {
        try {
            this.props.removeParticipant(participantId, isCandidate);
            // buscamos al participnate a eliminar
            var participants = this.state.totalParticipantes;
            let indexParticipante = participants.filter(participanteL => participanteL.participanteId !== participantId);
            
            participants = indexParticipante;
            var isEsperaParticipantes = true;
            if (participants.length > 1) {
                isEsperaParticipantes = false;
            }

            if (participants.length === 0 && this.state.isRecordProcess) {
                this.leaveRecording();
            }
            this.setState({
                totalParticipantes : participants,
                isEsperaParticipantes: isEsperaParticipantes
            });
        } catch (error) {
            message.error("Ocurrió un error al remover un participante a la sesión");
        }
    }

    verificateTotalParticipants()
    {
        try {
            if (this.state.totalParticipantes.length === 0) {

                if (this.state.isRecordProcess && this.state.recordId !== null) {
                    this.leaveRecording();
                }

                this.leaveSession();
            }
        } catch (error) {
            message.error("Ocurrió un error al verificar el total de participantes de la sesión");
        }
    }

    addAllParticipantStartSession()
    {
        try {

            var hash = {};
            let participants = this.state.subscribers.filter(function(current) {
            var exists = !hash[JSON.parse(current.stream.connection.data).clientId];
                hash[JSON.parse(current.stream.connection.data).clientId] = true;
                return exists;
            });

            // let participants = this.state.subscribers;
            if (participants.length > 0) {

                participants.forEach(streamManager => {
                    var participantId = JSON.parse(streamManager.stream.connection.data).clientId;
                    var isCandidate = JSON.parse(streamManager.stream.connection.data).isCandidate;

                    this.addParticipant(participantId, isCandidate);
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al agregar un nuevo participante a la sesión");
        }
    }

    verificateIfCandidateleave(isCandidate)
    {
        try {
            if (isCandidate) {
                if (this.state.mainStreamManager !== undefined) {
                    var mainIsCandidate = JSON.parse(this.state.mainStreamManager.stream.connection.data).isCandidate;
                    if (mainIsCandidate) {
                        // removemos al candidato de la lista de suscriptores en caso de existir
                        this.deleteSubscriber(this.state.mainStreamManager);
                        this.setState({
                            mainStreamManager : undefined,
                            streamCandidato: undefined,
                        });

                    }
                }
            }
        } catch (error) {
            message.error("Ocurrió un error al verificar si el candidato salió de la sesión");
        }
    }

    identificarCandidato()
    {
        try {
            let participants = this.state.subscribers;
            // identificamos al candidato para ponerlo como pantalla principal (solo para los entrevistadores)
            if (!this.props.objSession.isCandidate) {
                if (this.state.mainStreamManager === undefined) {
                    let candidate = participants.filter(strem => JSON.parse(strem.stream.connection.data).isCandidate === true);
                    let indexCandidato = participants.indexOf(strem => JSON.parse(strem.stream.connection.data).isCandidate === true);
                    if (candidate.length > 0) {
                        participants.splice(indexCandidato, 1);
                        this.setState({
                            mainStreamManager : candidate[0],
                            streamCandidato : candidate[0],
                            subscribers: participants,
                        });  
                    }
                    else
                    {
                        var videoCandidate = undefined;

                        if (this.state.streamCandidato !== undefined) {
                            videoCandidate = this.state.streamCandidato;
                        }

                        this.setState({
                            mainStreamManager : videoCandidate,
                        });
                    }
                }
                else{
                    // verificamos si el que esta en la pantalla principal es el candidato
                    var isCandidate = JSON.parse(this.state.mainStreamManager.stream.connection.data).isCandidate;
                    if (!isCandidate && this.state.streamCandidato !== undefined) {
                        participants.push(this.state.streamCandidato);
                        this.setState({
                            subscribers : participants,
                        });
                    }
                }
            }
        } catch (error) {
            message.error("Ocurrió un error al identificar al candidato");
        }
    }


    verificateParticipantSharedScreen(){
        try {
            let participants = this.state.subscribers;
            if (participants.length > 0) {
                let participantSharedScreen = participants.filter(strem => strem.stream.typeOfVideo === "SCREEN");
                if (participantSharedScreen !== undefined && participantSharedScreen.length > 0) {
                    this.setState({
                        mainStreamManager : participantSharedScreen[0],
                    });
                    // eliminamos el particiante de la lista de suscriptores
                    this.deleteSubscriber(participantSharedScreen[0]);
                }
                else
                {
                    var videoCandidate = undefined;
                    if (this.state.streamCandidato !== undefined) {
                        videoCandidate = this.state.streamCandidato;
                    }
                    this.setState({
                        mainStreamManager : videoCandidate,
                    })
                }
            }

            // identificamos al candidato en caso de no tener pantallas a compartir
            this.identificarCandidato();
        } catch (error) {
            message.error("Ocurrió un error al verificar si el participante esta compartiendo pantalla");
        }
    }


    verificateUserSharedScreen(userScreen){
        try {
            if (userScreen.stream.typeOfVideo === "SCREEN") {
                var videoUsuario = this.state.publisher;
                // identificamos si nuesto usuario estaba compartiendo pantalla
                if (videoUsuario.stream.typeOfVideo === "SCREEN") {
                    // dejamos de compartr pantalla
                    this.leaveShareScreen();
                }
                // accion a ejecutar cuando se comparta la pantalla
                // actualizamos el video principal a quien esta compartiendo la pantalla
                this.setState({
                    mainStreamManager: userScreen,
                });
                // removemos de la lista de suscriptores al que esta compartiendo pantalla
                this.deleteSubscriber(userScreen.stream.streamManager);
            }

            // identificamos si tenemos una pantalla asignada a compartir pantalla
            if (this.state.mainStreamManager !== undefined && this.state.mainStreamManager.stream.typeOfVideo === "SCREEN") {
                // identificamos al nombre del usuario que se unio al video
                var nombreUsuario = JSON.parse(userScreen.stream.connection.data).clientData;
                // identificamos al usuario que esta compartiendo pantalla
                var nombreUsuarioScreen = JSON.parse(this.state.mainStreamManager.stream.connection.data).clientData;
                // identificamos cuando el usuario dejo de compartir la pantalla y lo removemos de nuestra pantalla principal
                if (nombreUsuario === nombreUsuarioScreen && userScreen.stream.typeOfVideo !== "SCREEN") {
                    this.setState({
                        mainStreamManager: undefined,
                    });
                }
            }
            // identificamos al candidato en caso de no tener pantallas a compartir
            this.identificarCandidato();
        } catch (error) {
            message.error("Ocurrió un error al verificar si el participante esta compartiendo pantalla");
        }
    }

    /**
     * Método que valida si se esta compartiendo pantalla y si nuesto usuario que salio lo estaba compartiendo
     * @param {objeto tipo stream} userLeave 
     */
    verificateLeaveSessionIsScreen(userLeave){
        try {
            if (userLeave.stream.typeOfVideo === "SCREEN" && (this.state.mainStreamManager !== undefined && this.state.mainStreamManager.stream.typeOfVideo === "SCREEN")) {

                // identificamos al nombre del usuario que salio de la sesión
                var nombreUsuario = JSON.parse(userLeave.stream.connection.data).clientData;
                // identificamos al usuario que esta compartiendo pantalla
                var nombreUsuarioScreen = JSON.parse(this.state.mainStreamManager.stream.connection.data).clientData;
                // identificamos cuando el usuario dejo de compartir la pantalla y lo removemos de nuestra pantalla principal
                if (nombreUsuario === nombreUsuarioScreen) {
                    this.setState({
                        mainStreamManager: undefined,
                    });
                }
            }
            // identificamos al candidato en caso de no tener pantallas a compartir
            this.identificarCandidato();
        } catch (error) {
            message.error("Ocurrió un error al verificar si el participante que salio estaba compartiendo pantalla");
        }
    }

    leaveSession() {
        try {
            // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
            const mySession = this.state.session;
            var myVideo = this.state.publisher;
            // identificamos al nombre del usuario que se unio al video
            var userId = JSON.parse(myVideo.stream.connection.data).clientId;
            var isCandidate = JSON.parse(myVideo.stream.connection.data).isCandidate;

            // Empty all properties...
            this.OV = null;
            this.setState({
                session: undefined,
                mainStreamManager: undefined,
                streamCandidato: undefined,
                publisher: undefined,
                subscribers: [],
            });

            if (mySession) {
                mySession.disconnect();
            }

            this.clearIntervaloTime();
            // removemos el participante de la lista de usuarios en la sala
            this.removeParticipant(userId, isCandidate);
            var participantes = this.state.totalParticipantes;
            if (participantes.length > 0) {
                this.props.finalizar();
            }
        } catch (error) { 
            message.error("Ocurrió un error al dejar la sesión"); 
        }
    }

    getToken() {
        return this.createSession(this.state.mySessionId).then((sessionId) => this.createToken(sessionId));
    }

    createSession(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId, mediaMode: "ROUTED"});
            axios
                .post(_SERVER_OPENVIDU + '/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + _SECRET_OPENVIDU),
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                .then((response) => {
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response !== undefined) {
                        if (error.response.status === 409) {
                            resolve(sessionId);
                        } else {
                            if (
                                window.confirm(
                                    'No connection to OpenVidu Server. This may be a certificate error at "' +
                                    _SERVER_OPENVIDU +
                                    '"\n\nClick OK to navigate and accept it. ' +
                                    'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                                    _SERVER_OPENVIDU +
                                    '"',
                                )
                            ) {
                                window.location.assign(_SERVER_OPENVIDU + '/accept-certificate');
                            }
                        }
                    }
                    
                });
        });
    }

    createToken(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ session: sessionId });
            axios
                .post(_SERVER_OPENVIDU + '/api/tokens', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + _SECRET_OPENVIDU),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }

    render(){
    
        return(
            <div>
                <NotificationContainer />
                {this.state.mainStreamManager !== undefined ? (
                    <div id="main-video" >
                        {(this.state.totalParticipantes.length > 1) ? 
                        <div>
                            {/* video del candiato o de quien comparte pantalla */}
                            <div className={(this.state.subscribers.length > 0) ? "row video-con-participantes" : "row video-sin-participantes"}>
                                <div className="col-md-12 height-total">
                                    {/* indicamos si se muestra el icono de grabacion en curso */}
                                    {(this.state.isRecordProcess) &&
                                        <div className="etiqueta-grabacion">
                                            <p className="etiqueta-grabacion__label"><FontAwesomeIcon icon={faCircle} className="etiqueta-grabacion__icon" /> Grabando</p>
                                        </div>
                                    }
                                    <UserVideoComponent streamManager={this.state.mainStreamManager} />
                                </div>
                            </div>
                           
                            {this.state.publisher !== undefined  ? (
                                
                             <div  className="row tab-chats more-participants">
                                <div className="col-md-3 col-sm-3 height-total">
                                  <UserVideoComponent streamManager={this.state.publisher} />
                                </div>
                              </div>) : null
                            
                            }
                                   {/* demas participantes */}
                                {(this.state.subscribers.length > 0) &&
                                <div id="participantePrncipal" className="row tab-chats more-participants">
                                    {this.state.subscribers.map((sub, i) => (
                                        <div  key={i} className="col-md-3 col-sm-3 height-total">
                                            <UserVideoComponent streamManager={sub} />
                                        </div>
                                    ))}
                                </div>
                            }
                          
                            </div> 
                            : <div className="row video-sin-participantes">
                                <div className="col-md-12 height-total">
                                    {/* indicamos si se muestra el icono de grabacion en curso */}
                                    {(this.state.isRecordProcess) &&
                                        <div className="etiqueta-grabacion">
                                            <p className="etiqueta-grabacion__label"><FontAwesomeIcon icon={faCircle} className="etiqueta-grabacion__icon" /> Grabando</p>
                                        </div>
                                    }
                                    <div className="h-100 container d-flex align-items-center justify-content-center">
                                        <div>
                                            <img className="mas-participantes__imagen mb-2" src={masParticipantes} alt=""/>
                                            <div className="mas-participantes-integrantes">
                                                <p className="mas-participantes-integrantes__text text-white">En espera que los demás usuarios se unan a la llamada</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                ) : (
                    <div id="video-container">
                        {(this.state.totalParticipantes.length > 1) ? 
                        <div>
                            {/* indicamos si se muestra el icono de grabacion en curso */}
                            {(this.state.isRecordProcess) &&
                                <div className="col-md-12">
                                    <div className="etiqueta-grabacion">
                                        <p className="etiqueta-grabacion__label"><FontAwesomeIcon icon={faCircle} className="etiqueta-grabacion__icon" /> Grabando</p>
                                    </div>
                                </div>
                            }
                            
                            {/* participantes principales */}
                            <div id="participante-5" className={(this.state.subscribers.length > 4) ? "row video-con-participantes container justify-content-md-center" : "row video-sin-participantes container justify-content-md-center"}> 
                            
                                {this.state.subscribers.map((sub, i) => (
                                    (i < 4) ? (
                                    <div  key={i} className={(this.state.subscribers.length < 2) ? "col-md-12 height-total" : "col-md-6 col-sm-6 height-medio"}>
                                        <UserVideoComponent streamManager={sub} />
                                    </div>) : null
                                ))}
                            </div>
                            {/* demas participantes */}
                            {this.state.publisher !== undefined  ? (
                                
                                <div  className="row tab-chats more-participants">
                                   <div className="col-md-3 col-sm-3 height-total">
                                     <UserVideoComponent streamManager={this.state.publisher} />
                                   </div>
                                 </div>) : null
                               
                               }
                            {(this.state.subscribers.length > 4) &&
                                <div id="participante-1" className="row tab-chats more-participants">
                                    {this.state.subscribers.map((sub, i) => (
                                        (i > 3) ? (
                                        <div id={"participanteSeccion"+i} key={i} className="col-md-3 col-sm-3 height-total">
                                            <UserVideoComponent streamManager={sub} />
                                        </div>) : null
                                    ))}
                                </div>
                            }
                        </div> 
                        : <div className="row video-sin-participantes">
                                <div className="col-md-12 height-total">
                                    {/* indicamos si se muestra el icono de grabacion en curso */}
                                    {(this.state.isRecordProcess) &&
                                        <div className="etiqueta-grabacion">
                                            <p className="etiqueta-grabacion__label"><FontAwesomeIcon icon={faCircle} className="etiqueta-grabacion__icon" /> Grabando</p>
                                        </div>
                                    }
                                    <div className="h-100 container d-flex align-items-center justify-content-center">
                                        <div>
                                            <img className="mas-participantes__imagen mb-2" src={masParticipantes} alt=""/>
                                            <div className="mas-participantes-integrantes">
                                                <p className="mas-participantes-integrantes__text text-white">En espera que los demás usuarios se unan a la llamada</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div> 
                )}

                <Row className="tarjeta-video">
                    <div className={!this.props.objSession.isCandidate ? "contenedor-controles-entrevistador":"contenedor-controles-candidatos"}>
                        <Row>
                            <div className={!this.props.objSession.isCandidate ? "inicio-seccion-controles-interviewer":"inicio-seccion-controles-candidato"}>
                                <label className="inicio-seccion-controles-interviewer__text-tiempo">
                                    {(this.state.durationTime.hours > 0) &&
                                       this.state.durationTime.hours + ":"
                                    }
                                    {this.state.durationTime.minutes + ":" +this.state.durationTime.seconds}
                                </label>
                            </div>

                            <div className="div-iconos-control pl-2" onClick={this.props.goToChat}>
                                <input type="image" src={chat} className="div-iconos-control__imagenes" title="Chat" alt=""/>
                            </div>

                            {(!this.state.isSharedScreen) &&
                                <div className="div-iconos-control" onClick={this.shareScreen}>
                                    <input type="image" src={compartirPantalla} className="div-iconos-control__imagenes" title="Compartir pantalla" alt=""/>
                                </div>
                            }
                            {(this.state.isSharedScreen) &&
                                <div className="div-iconos-control" onClick={this.leaveShareScreen}>
                                    <input type="image" src={dejarCompartir} className="div-iconos-control__imagenes" title="Dejar de compartir pantalla" alt=""/>
                                </div>
                            }

                            {(!this.props.objSession.isCandidate && !this.state.isRecording && !this.state.isRecordProcess) &&
                                <div className="div-iconos-control" onClick={this.startRecord}>
                                    <input type="image" src={grabar} className="div-iconos-control__imagenes" title="Grabar" alt=""/>
                                </div>
                            }
                            {(!this.props.objSession.isCandidate && this.state.isRecording && this.state.isRecordProcess) &&
                                <div className="div-iconos-control" onClick={this.leaveRecording}>
                                    <input type="image" src={dejarGrabr} className="div-iconos-control__imagenes" title="Dejar de grabar" alt=""/>
                                </div>
                            }
                            {(!this.props.objSession.isCandidate && !this.state.isRecording && this.state.isRecordProcess) &&
                                <div className="div-iconos-control">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <input type="image" src={grabar} className="div-iconos-control__imagenes" alt=""/>
                                    </OverlayTrigger>
                                </div>
                            }

                            {(this.state.hasVideo) &&
                                <div className="div-iconos-control" onClick={() => this.muteOrUnMuterVideo(false)}>
                                    <input type="image" src={logoCamara} className="div-iconos-control__imagenes" title="Apagar camara" alt=""/>
                                </div>
                            }
                            {(!this.state.hasVideo) &&
                                <div className="div-iconos-control" onClick={() => this.muteOrUnMuterVideo(true)}>
                                    <input type="image" src={apagarCamara} className="div-iconos-control__imagenes" title="Habilitar camara" alt=""/>
                                </div>
                            }
                            

                            {(this.state.hasAudio) &&
                                <div className="div-iconos-control" onClick={() => this.muteOrUnMuterAudio(false)}>
                                    <input type="image" src={logoMicrofono} className="div-iconos-control__imagenes" title="Silenciar" alt=""/>
                                </div>
                            }
                            {(!this.state.hasAudio) &&
                                <div className="div-iconos-control" onClick={() => this.muteOrUnMuterAudio(true)}>
                                    <input type="image" src={silenciar} className="div-iconos-control__imagenes" title="Habilitar audio" alt=""/>
                                </div>
                            }
                            <div className="final-seccion-controles-interviewer" onClick={this.leaveSession}>
                                {
                                    this.props?.invalidQueue?.length === 0
                                        ?
                                    <input
                                        type="image"
                                        src={finLlamada}
                                        className="div-iconos-control__imagen-fin-llamada"
                                        title="Finalizar llamada"
                                        alt="Finalizar llamada"
                                    />
                                        :
                                    <Popover
                                        visible={true}
                                        overlayStyle={{ maxWidth: 280, textAlign: 'center' }}
                                        content="Algunas respuestas de la guia de entrevista contienen caracteres no permitidos"
                                    >
                                        <input
                                            type="image"
                                            src={finLlamadaDisabled}
                                            className="div-iconos-control__imagen-fin-llamada"
                                            title="Finalizar llamada"
                                            alt="Finalizar llamada"
                                            style={{ cursor: 'no-drop' }}
                                            disabled
                                        />
                                    </Popover>
                                }
                            </div>
                            
                        </Row>
                    </div>
                

                    {(!this.state.isCandidate && this.state.showLessTime) &&
                        <div className="contenedor-nuevos-componentes">
                            <Row>
                                <Col sm={6}  >
                                    <Row>
                                        <div className={(!this.state.showSolicitarTiempo) ? "tiempo-restante text-center label-amarillo" : "tiempo-restante text-center label-rojo"}>
                                            <label className="tiempo-restante__texto-concluye" style={{paddingLeft:"17px"}}>Concluye en:</label><br/>
                                            <label className="tiempo-restante__restante">-
                                                {this.state.restantTime.minutes > 0 &&
                                                    this.state.restantTime.minutes + ':'
                                                }
                                                {this.state.restantTime.seconds}
                                            </label>
                                        </div>
                                        <Col className={(!this.state.showSolicitarTiempo) ? "label-amarillo" : "label-rojo"}>
                                            <label></label>
                                        </Col>
                                    </Row>
                                </Col>
                                {(!this.state.showSolicitarTiempo) && 
                                    <Col sm={6}>
                                        <Row>
                                            <Col className="label-azul">
                                                <label></label>
                                            </Col>
                                            <div className="agregar-tiempo" onClick={this.props.requestMoreTime}>
                                                <label className="tiempo-restante__texto-concluye text-light text-center" style={{paddingLeft:"8px"}}>¿Agregar</label>
                                                <label className="tiempo-restante__restante text-light pt-1">+10:00</label>
                                                <label className="tiempo-restante__texto-concluye text-light">minutos más?</label>
                                            </div>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </div>
                    }
                </Row>
            </div>
        )
    }
}

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        La grabación ya fue iniciada
    </Tooltip>
);

export default VideoParticipante;