export const urlHashString = (name, url = window.location.href) => {
  
    url = url.split(`${name}=`);
    const result = url[1];
  
    if(!result){
      return null;
    }
    
    return result;
  }