import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../images/perfil-icon.jpg';
import { redirectEntepriseTypes } from '../../../utils/types/redirect';
import { _APP_BASE_URL_EMPRESA } from '../../../settings';

const Candidate = ({ interview }) => {
  return (
    <div className="table-body">
        <div className="table-body__avatar">
            <img src={interview.candidateInformation === null || interview.candidateInformation.urlAvatar === null ? logo : interview.candidateInformation.urlAvatar} alt="" />
        </div>
        <div>
          {
            interview.reportInterviewEnterpriseUrl
            ?
            <a
              href={`${_APP_BASE_URL_EMPRESA}/?${redirectEntepriseTypes.candidateReport}=${interview.reportInterviewEnterpriseUrl}`}
              className="table-body--blue table-body--bold mb-0"
              rel="noopener noreferrer"
              target='_blank'
            >
              {interview.candidateInformation === null ? "Sin datos del candidato" : interview.candidateInformation.name}
            </a>
            :
            <label className="table-body--blue table-body--bold mb-0">{interview.candidateInformation === null ? "Sin datos del candidato" : interview.candidateInformation.name}</label>
          }
        </div>
    </div>
  );
};

Candidate.propTypes = {
  
};

export default Candidate;