import React from "react";
import { Col, Row } from "react-bootstrap";
import advertencia from "../../images/advertenciaIcon.svg";

function BrowseSoport(props) {
    return(
        <div className="border-0">
            <div className=" contenedor-validacion">
                <Row style={{width : "100%"}}>
                    <Col sm={12} className="interrogacion">
                        <img className="interrogacion__imagen" src={advertencia} alt=""/>
                    </Col>
                    <Col sm={12} className="text-center seccion-informacion">
                        <label className="seccion-informacion__title">{props.message}</label>
                    </Col>
                    <Col sm={12} className="text-center seccion-informacion">
                        <button className="finalizar__boton" onClick={props.irEntrevistas} > Ir a mis entrevistas</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default BrowseSoport;