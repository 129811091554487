import axios from 'axios';
import { _SERVERBRIVE } from '../../../settings';

export const getInterviewerRefreshToken = ({ refresh_token }) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refresh_token);

  return axios({
      method: 'POST',
      url: `${_SERVERBRIVE}/auth/token`,
      data: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
  })
}