import _ from 'underscore';

export const unavailableTags = [ 'script', 'link', 'style', 'xml', 'a' ]

export const sanitizeHTMLString = ({ html = '', tags = unavailableTags }) => {
  let unescapeText = _.unescape(html)
  tags.forEach(function(tag) {
    // Crea una expresión regular para buscar la etiqueta HTML con sus atributos y contenido
    const regex = new RegExp(`<${tag}\\b[^>]*>([\\s\\S]*?)<\/${tag}>`, 'gi')
    // Reemplaza las etiquetas encontradas por una cadena vacía
    unescapeText = unescapeText.replace(regex, '')
  })
  return unescapeText;
}

export const isValidHTMLString = ({ html = '', tags = unavailableTags }) => {
  const unescapeText = _.unescape(html)
  // Crea una expresión regular para buscar las etiquetas HTML
  const regex = new RegExp('<(' + tags.join('|') + ')[\\s\\S]*?>', 'i')
  // Verifica si la cadena contiene alguna de las etiquetas
  return !regex.test(unescapeText);
}
