import React, { Component } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { NotesTextContainer } from "../../../Features/InterviewGuide/NotesTextContainer";
import { Tooltip } from "antd";
class Notas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Notas: undefined,
            cantidadLetras: 0,
            contadorNota: 0,
            dataNote: props.objectNote,
            changeNote: false,
            showToltip: false,
            validText: true,
        };
    }

    componentDidMount = () => {
        if (this.state.dataNote.id !== 0) {
            this._getInitialHTML(this.state.dataNote.note)
        }
    }

    /**
     * Transforma el texto enriquecido a un objeto para poder 
     * inicializarlo en en componente de texto enriquecido
     * @param {*} notes 
     */
     _getInitialHTML(notes) {
        const contentBlock = htmlToDraft(notes);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
            Notas: editorState,
        })
    }

    onChangeValid = (valid) => this.setState({ validText: valid })

    handleChange = (e) => {

        let cont = this.state.contadorNota;
        cont++;

        this.setState({
            contadorNota: cont,
            Notas: e,
            changeNote: true,
            // cantidadLetras: e.getCurrentContent().getPlainText().length
        });
        this.props.handleNota(e);
        this.saveOrUpadateNote();
    }

    /**
     * Guarda una nota en el caso de no existir, en caso contratio se actualiza el contenido de dicha nota
     */
    saveOrUpadateNote() {
        if (this.state.contadorNota >= 50 && !this.props.isProcessSave){
            this.props.saveNote();
            this.setState({
                contadorNota: 0,
            });
        }
    }

    saveNoteButton = async () => {
        if (!this.props.isProcessSave){
            this.props.saveNote();
            setTimeout(() => {
                this.verificarToltip();
            }, 1000);
        }
    }

    verificarToltip = async () => {
        if (this.props.isSaved) {
            await this.setState({
                showToltip: true,
            });

            setTimeout(() => {
                this.setState({
                    showToltip: false,
                });
            },5000);
        }
    }

    render() {
        const { changeNote } = this.state;
        return (
            <div>
                <Card className="border-0">
                    <Card.Body className="header-tab">
                        <label className="header-tab__title">Notas</label>
                    </Card.Body>
                </Card>
                <Card className="border-0">
                    <Card.Body className="pt-3 card-body-tabs">
                        <Row >
                            <Col sm={12} className="seccion-notas">
                                <NotesTextContainer
                                    value={this.state.Notas}
                                    onChange={this.handleChange}
                                    valid={this.state.validText}
                                    onChangeValid={this.onChangeValid}
                                />
                                <Tooltip
                                    visible={!this.state.validText}
                                    title="No podras guardar las notas, hasta eliminar los caracteres permitidos"
                                >
                                    <Button
                                        onClick={this.saveNoteButton}
                                        disabled={(changeNote ? false : true) || !this.state.validText}
                                        variant="light" size="sm" className="mt-1"
                                        style={{ marginLeft: "155px" }}>
                                        <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                </Tooltip>
                                <div className="contenedor-informacion-note">
                                {(this.state.showToltip) ? <label className="contenedor-informacion-note_text">Nota guardada</label> : null}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default Notas;