import React, { useEffect, useState } from 'react';

import ExternalLink from '../Shared/ExternalLink.jsx';

import CompatibilityInterviewImage from "../images/CompatibilityInterview.svg";

import "./style.css";
import { _SERVER } from '../../settings.js';
import { HeaderGet } from '../../lib/fecthObject.js';
import axiosExcecute from '../../lib/peticionApi';

const CompatibilityInterview = () => {

  const [downloadExplorerURL, setDownloadExplorerURL] = useState([]);

  const getExplorerLinks = async () => {
    var url = `${_SERVER}/api/Interview/compatible/browsers/download`;
    const fetchSchedules = HeaderGet(url, '');

    let response = await axiosExcecute(fetchSchedules);
    if (response.status === 200 && response.data) {
      setDownloadExplorerURL(response.data)
    }
  }

  useEffect(() => {
    getExplorerLinks();
  }, []);

  return (
    <div className="compatibility-interview">
      <div className="compatibility-interview__image">
        <img src={CompatibilityInterviewImage} alt="" />
      </div>
      <div className="compatibility-interview__text">
        <p>Este navegador no es compatible,</p>
        <p>usa los navegadores <ExternalLink href={downloadExplorerURL.chromeDownload} text="Chrome" /> ó <ExternalLink href={downloadExplorerURL.firefoxDownload} text="Firefox" /></p>
      </div>
    </div>
  );
};

CompatibilityInterview.propTypes = {

};

export default CompatibilityInterview;