import axios from 'axios';
import { _SERVER_BRIVE_CANDIDATE } from '../../../settings';

export const getAuthByHash = ({ hash }) => {
  return axios({
      method: 'POST',
      url: `${_SERVER_BRIVE_CANDIDATE}/auth/interview/user/token`,
      data: JSON.stringify(hash),
      headers: {
        'Content-Type': 'application/json',
      }
  })
}