import React from 'react';
import PropTypes from 'prop-types';
import "./RowFlex.css";

const RowFlex = ({ children }) => {
  return (
    <div className="row-flex">
      { children }
    </div>
  );
};

RowFlex.propTypes = {
  children: PropTypes.element
};

export default RowFlex;