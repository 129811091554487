import React, { Component } from 'react';
import logo from '../images/perfil-icon.jpg'
import './AgendarEntrevista.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import AgregarEntrevistador from "./AgregarEntrevistador";
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import CargaArchivo from '../cargaArchivo/CargaArchivos'
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import Loading from "../spinners/spinner";
import PageError from "../pagesInfo/error";
import { _HEADER, _HEADER_BRIVE, _NUM_MAX_TEXT, _SERVER, _SERVERBRIVE, _MB_MAX_FILES } from '../../settings';
import imgError from '../../images/error.png'
import userPlus from "../../images/userPlus.png";
import mapsPing from "../../images/maps.png"
import fileError from "../../images/fileError.webp"
import TextoEnrriquecido from '../textoEnrriquecido/TextoEnrriquecido';
import imgReloj from '../../images/reloj.png'
import { message } from 'antd';
const axios = require('axios');

class AgendarEntrevista extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidateId: props.match.params.id,
            assessorId: props.match.params.AssesorId,
            mensajeCandidatoAsesor: "",
            candidatoExist: true,
            habilitarAcciones: true,
            asesorExist: true,
            loading: false,
            error: false,
            showModal: false,
            verModal: false,
            visualizarModal: false,
            fechaActual: "",
            horaActual: "",
            form: {
                isVideoInterview: true,
                AssesorId: props.match.params.AssesorId,
                VacantId: 1,
                CandidateId: Number(props.match.params.id),
                AccessCode: "1",
                JobProfileId: 10
            },
            dateTime: {},
            files: [],
            filesCandidate: [],
            listEntrevistadores: [],
            timeZone: [],
            pageListNullEntrevistadores: false,
            ubicacion: false,
            observaciones: {
                candidato: undefined,
                entrevistador: undefined
            },
            cantidadLetrasCandidato: 0,
            cantidadLetrasEntrevistador: 0,
            dataCandidate: {},
            tiempoDuracionEntrevista: false,
            fechaActualParaValidacion: "",
            fileError: false,
            meetingEdit: Boolean(this.props.match.params.case),
            idSheduler: this.props.match.params.idSheduler,
            documentShedulerSavedCandidate: [],
            documentShedulerSavedInterviewer: []
        };

    }

    componentDidMount = async () => {

        await this.getTimeZone();

        let shedulerData = await this.getDataSheduler();

        if (shedulerData !== null) {
            this.setNotes(shedulerData)
            this.getDocumenteSheduler(shedulerData);
        } else if (this.state.meetingEdit) {
            return;
        }

        await this.getDataCandidateByid();
        await this.getDataAssessorbyId();
        this.validateCandidateAndAssesorExist();

        this.assignDate(shedulerData);

    }

    /**
     * Transforma el texto enriquecido a un objeto para poder 
     * inicializarlo en en componente de texto enriquecido
     * @param {*} notes 
     */
    _getInitialHTML(notes) {

        var contentState = stateFromHTML(notes);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }

    assignDate = (shedulerData) => {

        let fecha = new Date()
        let fechaActual = fecha.getFullYear() + "-" + this.valueLenght(fecha.getMonth() + 1) + "-" + this.valueLenght(fecha.getDate());

        let horaActual = this.valueLenght(fecha.getHours() + 2) + ":" + this.valueLenght(fecha.getMinutes());
        let horaActualFin = this.valueLenght(fecha.getHours() + 3) + ":" + this.valueLenght(fecha.getMinutes());

        if (shedulerData !== null) {
            let fechaActual = new Date();
            fechaActual.setHours(fechaActual.getHours() + 2);
            fecha = new Date(shedulerData.startTime);
            fechaActual = fecha.getFullYear() + "-" + this.valueLenght(fecha.getMonth() + 1) + "-" + this.valueLenght(fecha.getDate());
            if (fechaActual >= fecha) {
                horaActual = this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes());
                horaActualFin = this.valueLenght(fecha.getHours() + 1) + ":" + this.valueLenght(fecha.getMinutes());
            }
        }
          
        this.setState({
            fechaActual: fechaActual,
            horaActual: horaActual,
            dateTime: {
                ...this.state.dateTime,
                InterviewDate: fechaActual,
                StartTime: horaActual,
                EndTime: horaActualFin,
            },
            fechaActualParaValidacion: fechaActual,
        })
    }

    /**
     * Obteniendo datos para poder editar la agendada de una entrevista
     */

    getDataSheduler = async () => {
        const { idSheduler, meetingEdit } = this.state
        if (meetingEdit) {
            try {
                let dataSheduler = await axios.get(`${_SERVER}/api/schedule/${idSheduler}`, _HEADER);
                let data = dataSheduler.data
                await this.getInfoInterviewer(data.interviewerIdList.filter(id => id !== data.assesorId));
                this.setState({
                    form: {
                        ...this.state.form,
                        id: data.id,
                        place: data.place,
                        AccessCode: data.accessCode,
                        isVideoInterview: data.isVideoInterview,
                        JobProfileId: data.jobProfileId,
                        VacantId: 10,
                        TimeZone: data.timeZone
                    }
                });
                return data;
            } catch (error) {
                this.setState({
                    mensajeCandidatoAsesor: "La entrevista que desea editar ya no se encuentra disponible.",
                    habilitarAcciones: false
                })
                return null;
            }
        } return null
    }

    getInfoInterviewer = async (listId) => {
        let listEntrevistadores = [];
        listId.forEach(async (interviewer) => {
            let interviewerData = await axios.get(`${_SERVER}/api/asp/users/${interviewer}`, _HEADER);
            let data = interviewerData.data
            listEntrevistadores.push({
                id: data.id,
                nombre: data.name,
                avatar: data.urlAvatar !== null ? data.urlAvatar : logo
            })
        })
        this.setState({
            listEntrevistadores
        })
    }

    setNotes = (data) => {
        const { meetingEdit } = this.state
        if (meetingEdit !== undefined) {
            this.setState({
                observaciones: {
                    candidato: this._getInitialHTML(data.candidateComment),
                    entrevistador: this._getInitialHTML(data.interviewComment)
                }
            })
        }
    }

    getDocumenteSheduler = async (sheduler) => {
        const { meetingEdit } = this.state
        if (meetingEdit !== undefined) {
            try {
                let documents = await axios.get(`${_SERVER}/api/schedule/document/schedule/${sheduler.id}`, _HEADER)
                this.setState({
                    documentShedulerSavedCandidate: documents.data.filter(file => file.isCandidate === true),
                    documentShedulerSavedInterviewer: documents.data.filter(file => file.isCandidate === false)
                })
            } catch (error) {
                message.error("Ocurrió un error al obtener los documentos de la agenda");
            }
        }
    }

    /***********************************************************/


    validateCandidateAndAssesorExist = () => {
        try {
            if (this.state.candidatoExist && this.state.asesorExist) {
                this.setState({
                    mensajeCandidatoAsesor: "",
                    habilitarAcciones: true
                });
            }
            else {
                if (!this.state.candidatoExist && !this.state.asesorExist) {
                    this.setState({
                        mensajeCandidatoAsesor: "El candidato y el asesor no existen, verifique su información",
                        habilitarAcciones: false
                    });
                }

                if (!this.state.candidatoExist && this.state.asesorExist) {
                    this.setState({
                        mensajeCandidatoAsesor: "El candidato no existe, verifique su información",
                        habilitarAcciones: false
                    });
                }
                if (!this.state.asesorExist && this.state.candidatoExist) {
                    this.setState({
                        mensajeCandidatoAsesor: "El asesor no existe, verifique su información",
                        habilitarAcciones: false
                    });
                }
            }
        } catch (error) {
            message.error("Ocurrió un error al validar al candidato y asesor");
        }
    }

    getDataCandidateByid = async () => {
        try {
            var idCandidato = parseInt(this.state.candidateId.toString());
            if (!isNaN(idCandidato)) {
                await axios.get(_SERVERBRIVE + "/api/v1/candidates/" + idCandidato, _HEADER_BRIVE)
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                dataCandidate: response.data,
                                candidatoExist: true
                            })
                        }
                    })
                    .catch((response) => {
                        var error = Object.assign({}, response);
                        this.setState({
                            candidatoExist: false
                        });
                    });
            }
            else {
                this.setState({
                    candidatoExist: false
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la información del candidato");
            this.setState({
                candidatoExist: false
            });
        }
    }

    getDataAssessorbyId = async () => {
        try {
            var assessorId = this.state.assessorId.toString().trim();
            if (assessorId != undefined && assessorId != "") {
                await axios.get(_SERVER + "/api/asp/users/" + assessorId, _HEADER)
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                asesorExist: true
                            })
                        }
                    })
                    .catch((response) => {
                        var error = Object.assign({}, response);
                        this.setState({
                            asesorExist: false
                        });
                    });
            }
            else {
                this.setState({
                    asesorExist: false
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la información del asesor");
            this.setState({
                asesorExist: false
            });
        }
    }

    getTimeZone = async () => {
        try {
            let res = await axios.get(_SERVER + "/api/zonas/horarias", _HEADER);
            if (res.data.length > 0) {
                this.setState({
                    timeZone: res.data,
                    form: {
                        ...this.state.form,
                        TimeZone: res.data[30].timeZoneTypeId
                    }
                })
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener las zonas horarias");
        }
    }


    removeEntrevistador = (id, event) => {
        let listEntrevistadores = this.state.listEntrevistadores.filter(entrevistador => entrevistador.id !== id);
        this.setState({
            listEntrevistadores: listEntrevistadores
        })
    }

    addEntrevistador = (entrevistador, event) => {
        let listEntrevistadores = this.state.listEntrevistadores;
        listEntrevistadores.push(entrevistador);
        this.setState({
            listEntrevistadores: listEntrevistadores,
            pageListNullEntrevistadores: false
        })
    }

    mostrarModal = () => {
        this.setState({
            showModal: true,
        });
    };

    handleCloseModal = event => {
        this.setState({
            ...this.state.showModal,
            showModal: false
        })
    }

    handleChange = event => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        })
    }

    handleHorario = async (event) => {
        if (event.target.name.includes('EndTime')) {
            await this.validarHoraFin(event.target.value);
        } else if (event.target.name.includes('StartTime')) {
            await this.validarHoraInicio(event.target.value);
        } else {
            await this.validarFecha(event.target.value);
        }
    }

    validarHoraFin = (value) => {
        let confirmar = false;
        if (value == "" || value == null || value == undefined) {
            confirmar = true;
            return confirmar
        } else {

            let horaFinmayor;
            let horaInicio = new Date(this.state.fechaActual + " " + this.state.dateTime.StartTime);
            let horafin = new Date(this.state.fechaActual + " " + value);
            horaFinmayor = this.valueLenght(horaInicio.getHours()) + ":" + this.valueLenght(horaInicio.getMinutes()) + ":" + this.valueLenght(0);
            if (horafin.getHours() <= horaInicio.getHours()) {
                horaFinmayor = this.valueLenght(horaInicio.getHours() + 1) + ":" + this.valueLenght(horaInicio.getMinutes()) + ":" + this.valueLenght(0);
            } else {

                this.cambiarEstatusDeModal();
                var inicioMiliSegundos = horaInicio.getTime();
                var finMiliSegundos = horafin.getTime();
                var diferenciaHoras = finMiliSegundos - inicioMiliSegundos;

                horaInicio.setHours(horaInicio.getHours() + 1);
                if (horaInicio >= horafin ) {
                    horaFinmayor = this.valueLenght(horaInicio.getHours()) + ":" + this.valueLenght(horaInicio.getMinutes()) + ":" + this.valueLenght(0);
                }
                else
                {
                     if (diferenciaHoras > 7200000) {
                        this.setState({
                            tiempoDuracionEntrevista: true,
                        });
                        horaFinmayor = this.valueLenght(horaInicio.getHours()) + ":" + this.valueLenght(horaInicio.getMinutes()) + ":" + this.valueLenght(0);
                    } else {
                        horaFinmayor = this.valueLenght(horafin.getHours()) + ":" + this.valueLenght(horafin.getMinutes()) + ":" + this.valueLenght(0);
                    }
                }
               
            }
            this.setState({
                dateTime: {
                    ...this.state.dateTime,
                    EndTime: horaFinmayor,
                },
            });
            return confirmar
        }

    }

    validarFecha = (value) => {
        let confirmar = false;
        if (value == "" || value == null || value == undefined) {
            confirmar = true;
            return confirmar
        } else {
            let horaActual = this.state.horaActual;
            let horaFinmayor;
            let fecha = new Date();
            let fechaActual = fecha.getFullYear() + "-" + this.valueLenght(fecha.getMonth() + 1) + "-" + fecha.getDate();
            if (fechaActual === value) {
                horaActual = this.valueLenght(fecha.getHours() + 2) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
                horaFinmayor = this.valueLenght(fecha.getHours() + 3) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);

            } else {
                horaActual = this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
                horaFinmayor = this.valueLenght(fecha.getHours()+ 1)  + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
            }
            this.setState({
                fechaActualParaValidacion: value,
                dateTime: {
                    ...this.state.dateTime,
                    InterviewDate: value,
                    StartTime: horaActual,
                    EndTime: horaFinmayor,
                },
            });
            return confirmar
        }
    }

    validarHoraInicio = (value) => {
        let confirmar = false;
        if (value == "" || value == null || value == undefined) {
            confirmar = true;
            return confirmar
        } else {

            // obtenemos la hora de inicio
            let fechaHoraInicio = new Date(this.state.dateTime.InterviewDate + " " + value);
            // obtenemos la fecha actual
            let fechaActualValida = new Date();
            let fechaFinDia = new Date(this.state.dateTime.InterviewDate + " " + value);
            fechaFinDia.setHours(23);
            fechaFinDia.setMinutes(59);
            fechaFinDia.setMilliseconds(59);

            let fecha11 = new Date(this.state.dateTime.InterviewDate + " " + value);
            fecha11.setHours(23);
            fecha11.setMinutes(0);
            fecha11.setMilliseconds(0);

            fechaActualValida.setHours(fechaActualValida.getHours() + 2);
            fechaActualValida.setSeconds(0);
            let horaInicio = "";
            let horaFin = "";
            if (fechaHoraInicio >= fechaActualValida) {
                horaInicio = this.valueLenght(fechaHoraInicio.getHours()) + ":" + this.valueLenght(fechaHoraInicio.getMinutes()) + ":" + this.valueLenght(0);
                horaFin = this.valueLenght(fechaHoraInicio.getHours() + 1) + ":" + this.valueLenght(fechaHoraInicio.getMinutes()) + ":" + this.valueLenght(0);

                if (fechaHoraInicio.getTime() == fecha11.getTime()) {
                    horaFin = this.valueLenght(fechaHoraInicio.getHours() + 2) + ":" + this.valueLenght(fechaHoraInicio.getMinutes()) + ":" + this.valueLenght(0);
                }
            }
            else
            {
                if (fechaActualValida < fechaFinDia) {
                    horaInicio = this.valueLenght(fechaActualValida.getHours()) + ":" + this.valueLenght(fechaActualValida.getMinutes()) + ":" + this.valueLenght(0);
                    horaFin = this.valueLenght(fechaActualValida.getHours() + 1) + ":" + this.valueLenght(fechaActualValida.getMinutes()) + ":" + this.valueLenght(0);

                    if (fechaActualValida.getTime() == fecha11.getTime()) {
                        horaFin = this.valueLenght(fechaActualValida.getHours() + 2) + ":" + this.valueLenght(fechaActualValida.getMinutes()) + ":" + this.valueLenght(0);
                    }
                }
                else
                {
                    horaInicio = this.valueLenght(fechaFinDia.getHours()+1) + ":" + this.valueLenght(fechaFinDia.getMinutes()) + ":" + this.valueLenght(0);
                    horaFin = this.valueLenght(fechaFinDia.getHours() + 1) + ":" + this.valueLenght(fechaFinDia.getMinutes()) + ":" + this.valueLenght(0);
                }
            }
            this.setState({
                dateTime: {
                    ...this.state.dateTime,
                    StartTime: horaInicio,
                    EndTime: horaFin,
                },
            }); 
            return confirmar
        }
    }
    cambiarEstatusDeModal = () => {
        this.setState({
            tiempoDuracionEntrevista: false,
        });
    }
    cambiarHora(horario, tiempoAniadir) {
        let milisegundos = 1000 * 60 * 60 * tiempoAniadir;
        let fecha = new Date(horario.getTime() + milisegundos);
        return this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
    }

    handleChangeSwitch = event => {
        this.setState({
            form: {
                ...this.state.form,
                isVideoInterview: event.target.checked
            }
        })
    }

    handleLoadFileCandidate = (file, e) => {

        if (file !== undefined && (this.state.filesCandidate.length + this.state.documentShedulerSavedCandidate.length) < 5) {
            var fileSizeMB = (file.size/1024)/1024;
            if (fileSizeMB <= _MB_MAX_FILES) {
                let files = this.state.filesCandidate;
                files.push(file);
                this.setState({
                    filesCandidate: files
                })
            } else { this.setState({ fileError: true }) }
        }




    }

    handleLoadFile = async (file, e) => {
        if (file !== undefined && (this.state.files.length + this.state.documentShedulerSavedInterviewer.length) < 5) {
            var fileSizeMB = (file.size/1024)/1024;
            if (fileSizeMB <= _MB_MAX_FILES) {
                let files = this.state.files;
                files.push(file);
                this.setState({
                    files: files
                })
            } else { this.setState({ fileError: true }) }
        }
    }

    handleSubmit(event) {
        alert('Your favorite flavor is: ' + this.state.value);
        event.preventDefault();
    }

    removeFile = (file, event) => {
        let files = this.state.files.filter(stateFile => stateFile.name !== file.name)
        this.setState({
            files: files
        })
    }

    removeFileCandidate = (file, event) => {
        let files = this.state.filesCandidate.filter(stateFile => stateFile.name !== file.name)
        this.setState({
            filesCandidate: files
        })
    }

    removeFileSaved = async (file, isFileCandidate) => {
        let listFile;

        try {
            file.isDelete = true;
            let response = await axios.put(`${_SERVER}/api/schedule/document`, file, _HEADER)

            if (isFileCandidate) {
                listFile = this.state.documentShedulerSavedCandidate.filter(document => document.id !== file.id);
                this.setState({
                    documentShedulerSavedCandidate: listFile
                })
            } else {
                listFile = this.state.documentShedulerSavedInterviewer.filter(document => document.id !== file.id);
                this.setState({
                    documentShedulerSavedInterviewer: listFile
                })
            }
        } catch (error) {
            message.error("Ocurrió un error al remover un archivo");
        }



    }

    encodeFileToBase64(fileForm) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileForm);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    /**
     * Obtenemos las notas para la entrevista de los textbox con texto enriquecido
     */
    getNotasEntrevista = () => {
        var observacionesEntrevistador = this.state.observaciones.entrevistador !== undefined ? draftToHtml(convertToRaw(this.state.observaciones.entrevistador.getCurrentContent())) : '';
        var observacionesCandidato = this.state.observaciones.candidato !== undefined ? draftToHtml(convertToRaw(this.state.observaciones.candidato.getCurrentContent())) : '';
        this.setState({
            form: {
                ...this.state.form,
                InterviewComment: observacionesEntrevistador,
                CandidateComment: observacionesCandidato
            }
        });
    }

    /**
     * Enviamos los datos para la entrevista
     */
    enviarDatosEntrevista = async () => {
        if (this.state.form.place === "" || this.state.form.place === undefined) {
            this.setState({ ubicacion: true })
            return;
        }

        if (this.state.listEntrevistadores.length === 0) {
            this.setState({ pageListNullEntrevistadores: true })
            return;
        }
        if (this.validarFecha(this.state.dateTime.InterviewDate)) {
            this.setState({ error: true })
            return;
        }
        if (this.validarHoraInicio(this.state.dateTime.StartTime)) {
            this.setState({ error: true })
            return;
        }
        if (this.validarHoraFin(this.state.dateTime.EndTime)) {
            this.setState({ error: true })
            return;
        }
        await this.getNotasEntrevista();
        await this.getListIdEntrevistadores();
        await this.getHorarioEntrevista();

        try {
            this.setState({ loading: true });

            const response = this.state.meetingEdit ?
                await axios.put(_SERVER + '/api/Schedule', this.state.form, _HEADER) :
                await axios.post(_SERVER + '/api/Schedule', this.state.form, _HEADER);

            let idSheduler = this.state.meetingEdit ? this.state.form.id : response.data.id

            for (let index = 0; index < this.state.files.length; index++) {
                await this.enviarDocumento(idSheduler, this.state.files[index], false);
            }

            for (let index = 0; index < this.state.filesCandidate.length; index++) {
                await this.enviarDocumento(idSheduler, this.state.filesCandidate[index], true);
            }

            await this.enviarMeeting(idSheduler);

            setTimeout(async () => {
                this.setState({ loading: false });
                this.props.history.push(`/mis-entrevistas-brive/${this.state.assessorId}`);
            }, 100);
        } catch (error) {
            message.error("Ocurrió un error al crear la agenda");
            this.setState({
                loading: false,
                error: true
            })
        }
    }

    enviarMeeting = async (idAgenda) => {
        try {
            let res = await axios.get(_SERVER + "/api/Schedule/send/meeting/" + idAgenda, _HEADER)
        } catch (error) {
        }

    }

    /**
     * Enviamos un documento para la entrevista
     * @param {*} scheduleId 
     * @param {*} file 
     */
    enviarDocumento = async (scheduleId, file, isCandidate) => {
        try {
            let documentB64 = await this.encodeFileToBase64(file);
            let dataFile = {
                "scheduleId": scheduleId,
                "documentContent": documentB64.split(',')[1],
                "fileName": file.name,
                "participanId": "0",
                "isCandidate": isCandidate
            }
            return await axios.post(_SERVER + '/api/schedule/document', dataFile, _HEADER);

        } catch (error) {
            message.error("Ocurrió un error al guardar un documentos de la agenda");
            return null
        }
    }

    /**
     * Obtenemos los id de los entrevistadores
     */
    getListIdEntrevistadores = () => {
        let ids = [];
        this.state.listEntrevistadores.forEach(entrevistador => { ids.push(String(entrevistador.id)); });
        this.setState({
            form: {
                ...this.state.form,
                InterviewerIdList: ids
            }
        })
    }

    /**
     * Recuperamos el horario de la entrevista
     */
    getHorarioEntrevista = () => {
        let date = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.StartTime);
        let horafinal = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.EndTime);
        let horaInicio = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.StartTime);
        this.setState({
            form: {
                ...this.state.form,
                InterviewDate: this.convertTimeUTC(date),
                EndTime: this.convertTimeUTC(horafinal),
                StartTime: this.convertTimeUTC(horaInicio),
            }
        })
    }

    /**
     * Formateamos una fecha GMT a UTC, pora tenen un horaerio mas exacto
     * @param {*} fecha 
     */
    convertTimeUTC(fecha) {
        var objFecha = new Date();
        objFecha.setUTCDate(fecha.getDate());
        objFecha.setUTCMonth(fecha.getMonth());
        objFecha.setUTCFullYear(fecha.getFullYear());
        objFecha.setUTCHours(fecha.getHours());
        objFecha.setUTCMinutes(fecha.getMinutes());
        objFecha.setUTCSeconds(fecha.getSeconds());
        return objFecha
    }
 
    onEditorStateChange = (editorState) => {
        this.setState({
            observaciones: {
                ...this.state.observaciones,
                candidato: editorState,
            },
            cantidadLetrasCandidato: editorState.getCurrentContent().getPlainText().length,

        });


    }
    onEditorStateChangeEntre = (editorState) => {
        this.setState({
            observaciones: {
                ...this.state.observaciones,
                entrevistador: editorState,
            },
            cantidadLetrasEntrevistador: editorState.getCurrentContent().getPlainText().length,
        });
    }

    handleCloseModalErrors = () => {
        this.setState({
            error: false,
            pageListNullEntrevistadores: false,
            ubicacion: false,
            tiempoDuracionEntrevista: false,
            fileError: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Container className="margin-top-content">
                    <Card>
                        <Card.Title className="container-form-agenda p-3">
                            <div className="row mb-2">
                                <div className="col-sm-8">
                                    <label className="text-form-agenda">Lugar</label>
                                    <input type="text" className="form-control fuente-texto" name="place" value={this.state.form.place || ''}
                                        onChange={this.handleChange} placeholder="ej. Oficinas centrales" />
                                </div>
                                <div className="col-sm-4 ">
                                    <div className="form-inline seccion-switch">
                                        <label className="mr-2 fuente-texto"> Video entrevista  </label>
                                        <div className="outerDivFull" >
                                            <div className="switchToggle">
                                                {this.state.form.isVideoInterview ?
                                                    <input type="checkbox" id="switch"
                                                        name="isVideoEntrevista"
                                                        defaultChecked={true}
                                                        onChange={this.handleChangeSwitch} /> :
                                                    <input type="checkbox" id="switch"
                                                        name="isVideoEntrevista"
                                                        defaultChecked={false}
                                                        onChange={this.handleChangeSwitch} />

                                                }
                                                <label htmlFor="switch"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="text-form-agenda">Fecha</label><br />
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <input
                                                className="form-control fuente-texto"
                                                type="date"
                                                name="InterviewDate"
                                                min={this.state.fechaActual}
                                                value={this.state.dateTime.InterviewDate || this.state.fechaActual}
                                                onChange={this.handleHorario}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <label className="text-form-agenda">Inicio</label><br />
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <input
                                                className="form-control fuente-texto pl-1 pr-0"
                                                type="time"
                                                onChange={this.handleHorario}
                                                min={this.state.horaActual}
                                                value={this.state.dateTime.StartTime || this.state.horaActual}
                                                name="StartTime" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <label className="text-form-agenda">Termino</label><br />
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <input
                                                className="form-control fuente-texto pl-1 pr-0"
                                                type="time"
                                                onChange={this.handleHorario}
                                                min={this.state.horaActual}
                                                value={this.state.dateTime.EndTime || this.state.horaActual}
                                                name="EndTime" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 div-zona-horaria">
                                    <select name="TimeZone" onChange={this.handleChange} value={this.state.form.TimeZone} className="form-control">
                                        {
                                            this.state.timeZone.map((item, i) =>
                                                <option key={i} value={item.timeZoneTypeId}>{item.description}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </Card.Title>
                        <Card.Body className="pt-0">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="col-sm-12 pl-0">
                                        <h5>Datos del candidato</h5>

                                        <div className="row mb-3 seccion-participates">
                                            <div className="col-xs-12 col-sm-9">
                                                <div className="panel panel-default">
                                                    <div className="panel-body">
                                                        <div className="profile__avatar-agendar">
                                                            {this.state.candidatoExist === true &&
                                                                <img src={logo} alt="" />
                                                            }
                                                        </div>
                                                        <div className="profile__header">
                                                            <label>{this.state.dataCandidate.Name}</label><br />
                                                            <label className="text-correo">{this.state.dataCandidate.Email}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 pt-2">
                                                <h6>Observaciones para el candidato</h6>
                                                <Row>
                                                    <Col sm={12}>
                                                        <TextoEnrriquecido
                                                            respuesta={this.state.observaciones.candidato}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} className="label-contador">
                                                        <label >{this.state.cantidadLetrasCandidato}/{_NUM_MAX_TEXT}</label>
                                                    </Col>
                                                </Row>
                                                <div className="row mt-1">
                                                    <div className="col clearfix">
                                                        <label className="text-bold ml-2">{this.state.filesCandidate.length + this.state.documentShedulerSavedCandidate.length}/5</label>
                                                        <div className="float-right">
                                                            {(this.state.filesCandidate.length + this.state.documentShedulerSavedCandidate.length) < 5 &&
                                                                <CargaArchivo loadFile={this.handleLoadFileCandidate} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 seccion-documentos-agendar mt-2">
                                                {this.state.documentShedulerSavedCandidate.map((file, i) => (
                                                    <div key={i} className="item-documento">
                                                        <div className="documento">
                                                            <div className="col clearfix">
                                                                <label className="nombre-documentos">{file.documentDocumentName}</label>
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={faTimes} className="text-secondary" onClick={(e) => this.removeFileSaved(file, true)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                                {this.state.filesCandidate.map((file, i) => (
                                                    <div key={i} className="item-documento">
                                                        <div className="documento">
                                                            <div className="col clearfix">
                                                                <label className="nombre-documentos">{file.name}</label>
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={faTimes} className="text-secondary" onClick={(e) => this.removeFileCandidate(file, e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <vr />
                                    <div className="col-sm-12 pr-0">
                                        <h5>Datos del entrevistador</h5>
                                        <div className="row mb-3 seccion-participates">
                                            <div className="col-sm-9">
                                                <div className="scrollmenu">
                                                    {this.state.listEntrevistadores.map((entrevistador) => (
                                                        <div className="elemento" key={entrevistador.id}>
                                                            <div className="etiqueta-entrevistadores ">
                                                                <Row>
                                                                    <Col className="pr-1">
                                                                        <div className="profile__avatar-agendar">
                                                                            <img src={entrevistador.avatar} alt="" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="pr-0 pl-0">
                                                                        <label className=" pt-1 nombre-entrevistador">{entrevistador.nombre}</label>
                                                                    </Col>
                                                                    <Col>
                                                                        <FontAwesomeIcon icon={faTimes} onClick={(e) => this.removeEntrevistador(entrevistador.id, e)} className="pt-2 pr-1 remove-entrevistador" />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-auto" >
                                                <Button variant="outline-primary" className="redondo" onClick={() => this.mostrarModal()} disabled={!this.state.habilitarAcciones}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </div>

                                        </div>

                                        <div className="row pt-2">
                                            <div className="col-sm-12">
                                                <h6>Observaciones para el entrevistador</h6>
                                                <Row>
                                                    <Col sm={12}>
                                                        <TextoEnrriquecido
                                                            respuesta={this.state.observaciones.entrevistador}
                                                            onEditorStateChange={this.onEditorStateChangeEntre}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} className="label-contador">
                                                        <label >{this.state.cantidadLetrasEntrevistador}/{_NUM_MAX_TEXT}</label>
                                                    </Col>
                                                </Row>
                                                <div className="row mt-1">
                                                    <div className="col clearfix">
                                                        <label className="text-bold ml-2">{this.state.files.length + this.state.documentShedulerSavedInterviewer.length}/5</label>
                                                        <div className="float-right">
                                                            {(this.state.files.length + this.state.documentShedulerSavedInterviewer.length) < 5 &&
                                                                <CargaArchivo loadFile={this.handleLoadFile} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 seccion-documentos-agendar mt-2">
                                            {this.state.documentShedulerSavedInterviewer.map((file, i) => (
                                                <div key={i} className="item-documento">
                                                    <div className="documento">
                                                        <div className="col clearfix">
                                                            <label className="nombre-documentos">{file.documentDocumentName}</label>
                                                            <div className="float-right">
                                                                <FontAwesomeIcon icon={faTimes} className="text-secondary" onClick={(e) => this.removeFileSaved(file, false)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {this.state.files.map((file, i) => (
                                                <div key={i} className="item-documento">
                                                    <div className="documento">
                                                        <div className="col clearfix">
                                                            <label className="nombre-documentos">{file.name}</label>
                                                            <div className="float-right">
                                                                <FontAwesomeIcon icon={faTimes} className="text-secondary" onClick={(e) => this.removeFile(file, e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Body>
                            <Row>
                                <Col md={{ span: 6, offset: 6 }}>
                                    <Container>
                                        <Row>
                                            <Col md={6} xs={12}><Button className="btn-block m-1" variant="outline-primary" >Cancelar</Button></Col>
                                            <Col md={6} xs={12}><Button className="btn-block m-1" variant="primary" onClick={() => this.enviarDatosEntrevista()} disabled={!this.state.habilitarAcciones}>Agendar Entrevista</Button></Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>


                        </Card.Body>
                    </Card>
                </Container>


                <AgregarEntrevistador
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    addEntrevistador={this.addEntrevistador}
                    listEntrevistadores={this.state.listEntrevistadores} />

                { this.state.loading ? <Loading msj="Creando entrevista." /> : null}
                { this.state.error ? <PageError onHide={this.handleCloseModalErrors} imgError={imgError} msj="Ocurrió un error, inténtelo de nuevo. " /> : null}
                { this.state.pageListNullEntrevistadores ? <PageError onHide={this.handleCloseModalErrors} imgError={userPlus} msj="Agrega por lo menos un entrevistador." /> : null}
                { this.state.ubicacion ? <PageError onHide={this.handleCloseModalErrors} imgError={mapsPing} msj="Especifique el lugar de la entrevista" /> : null}
                { this.state.tiempoDuracionEntrevista ? <PageError onHide={this.handleCloseModalErrors} imgError={imgReloj} msj="Ha excedido el tiempo máximo de la entrevista: 2 horas." /> : null}
                { !this.state.habilitarAcciones ? <PageError onHide={this.handleCloseModalErrors} imgError={imgError} msj={this.state.mensajeCandidatoAsesor} /> : null}
                {this.state.fileError ? <PageError onHide={this.handleCloseModalErrors} imgError={fileError} msj="el peso máximo del archivo debe ser a 100 mb." /> : null}

            </React.Fragment>
        )
    }

    valueLenght(value) {
        return value <= 9 ? "0" + value : value;
    }
}


export default AgendarEntrevista;