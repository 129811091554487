import axios from 'axios';
const newAxios = axios.create();

export default async function axiosExecute(...args){
    try {
        const response = await newAxios(...args);
        return response;
    } catch (error) {
        return error.response;
    }
}