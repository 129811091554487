import { createFetch } from "../../lib/fecthObject";
import axios from "../../lib/peticionApi";
import { _SERVERBRIVE } from "../../settings";
import { httpVerbTypes } from "../../utils/types/httpServices.js";

/** 
 * Endpoint que permite cancelar una entrevista desde el dsahboard de entrevistas
* @param {string} token: Id de la entrevista a cancelar 
*/
export const getInterviewerInformation = async ({ token }) => {
  const url = `${_SERVERBRIVE}/api/v1/User/Profile`;
  const fetch = createFetch({
    method: httpVerbTypes.get,
    url,
    token,
  });
  const response = await axios(fetch);
  return response;
}