import { Button } from 'react-bootstrap';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const CargaAvatar = ({ loadFile, src }) => {
    const inputFile = useRef(null);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileInput = (e) => {
        loadFile(e.target.files[0])
    }

    return (
        <div>
            <img className="foto-perfil" src={src} onClick={onButtonClick} />
            <input type='file' id='file' onChange={handleFileInput} ref={inputFile} style={{ display: 'none' }} accept="image/*"/>
        </div>
    )
}

export default CargaAvatar