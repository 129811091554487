import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ href, text }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      { text }
    </a>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ExternalLink;