import axios from 'axios';
import { _SERVER_BRIVE_CANDIDATE } from '../../../settings';

export const getCandidateRefreshToken = ({ refresh_token }) => {
  return axios({
      method: 'POST',
      url: `${_SERVER_BRIVE_CANDIDATE}/auth/token`,
      data: `refresh_token=${refresh_token}&grant_type=refresh_token`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
  })
}