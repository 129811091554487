import React, { useEffect, useState } from 'react';

import ExternalLink from "../Shared/ExternalLink.jsx";

import useOpenViduCompatibility from "../../hooks/useOpenViduCompatibility";
import { _SERVER } from '../../settings';
import axiosExcecute from '../../lib/peticionApi';
import "./style.css";
import { HeaderGet } from '../../lib/fecthObject.js';

const CompatibilityHeadband = ({ toggleCompatibility }) => {
  const { compatibility } = useOpenViduCompatibility();

  const [downloadExplorerURL, setDownloadExplorerURL] = useState([]);

  const getExplorerLinks = async () => {
    var url = `${_SERVER}/api/Interview/compatible/browsers/download`;
    const fetchSchedules = HeaderGet(url, '');

    let response = await axiosExcecute(fetchSchedules);
    if (response.status === 200 && response.data) {
      setDownloadExplorerURL(response.data)
    }
  }

  useEffect(
    () => {
      toggleCompatibility(compatibility);
    },
    [compatibility]
  );

  useEffect(() => {
    getExplorerLinks();
  }, []);

  if (compatibility) return null;

  return (
    <div className='compatibility-headband'>
      {
        !compatibility
        &&
        <p>
          Utiliza los navegadores <ExternalLink href={downloadExplorerURL.chromeDownload} text="Chrome" /> ó <ExternalLink href={downloadExplorerURL.firefoxDownload} text="Firefox" /> al realizar tus videoentrevistas
        </p>
      }
    </div>
  );
};


CompatibilityHeadband.propTypes = {

};

export default CompatibilityHeadband;