import React from 'react';
import confirmacionimg from '../images/confirmar.svg';
import rechazo from '../images/rechazo.svg';
import espera from '../images/posponer.svg';
import ComponenteConfirmacion from './ComponenteConfirmacion'
import ComponentePosponer from './ComponentePosponer'
import ComponenteRechazo from './ComponenteRechazo'
import './confirmacion.css';
import { _SERVER } from "../../settings";
// ejecutor axios
import axiosExcecute from '../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../lib/cookieSession';
// objetos fetch
import { HeaderGet, HeaderPut } from '../../lib/fecthObject';
import { message } from 'antd';
class Confirmacion extends React.Component {

    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search); 
        const empresa = params.get('emp');
        const isCandidate = params.get('rol');
        const accessCode = params.get('ac');
        const userId = params.get('ui');
        const estatusId = params.get('est');

        this.state = {
            update: false,
            error: false,
            confirmar: "",
            rechazar: "Has rechazado la entrevista",
            pospuesto: "Has pospuesto la entrevista",
            status: Number(estatusId),
            showAlert: false,
            styelAlert: "",
            mensajeAlert: "Cargando...",
            isError: false,
            habilitarBoton: false,
            loading: false,
            msjButton: "Enviar",
            isCandidate: isCandidate === 'True' ? true : false,
            nombreEmpresa: empresa,
            accessCode: accessCode,
            userId: userId,
        }

    }

    componentDidMount = async () => {
        if (this.state.status === 11) {
            await this.enviarConfirmacion();
        }
    }

    /**
     * Método que envia la confirmacion de la participación a la entrevista
     */
    enviarConfirmacion = async () => {
        let data = {
            accessCode: this.state.accessCode,
            statusId: this.state.status,
            participantId: this.state.userId,
            isCandidate: this.state.isCandidate,
        }

        try {
            var accion = "";
            var mensajeExito = "";
            if (this.state.status === 11) {
                accion = "confirmar su participación";
                mensajeExito = "Tu entrevista ha sido agendada.";
            }
            else {
                accion = "rechazar su participación";
                mensajeExito = "Entrevista rechazada."
            }
            
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/change/status/meeting`;
            const fetchMeeting = HeaderPut(url, tokenVideo, data);

            let response = await axiosExcecute(fetchMeeting);
            if (response.status === 200 && response.data != null) {
                switch (response.data) {
                    // exito
                    case 200:
                        this.setState({
                            mensajeAlert: mensajeExito,
                            isError: false,
                        });
                        break;
                    // la agenda esta en curso
                    case 300:
                        this.setState({
                            mensajeAlert: "No se pudo " + accion + ", la entrevista esta en curso.",
                            isError: true,
                        });
                        break;
                    // no se encontro la entrevista
                    case 400:
                        this.setState({
                            mensajeAlert: "No se encontro la entrevista o el participante, verifique su información.",
                            isError: true,
                        });
                        break;
                    // la entrevista ya termino
                    case 600:
                        this.setState({
                            mensajeAlert: "No se pudo " + accion + ", la entrevista concluyó.",
                            isError: true,
                        });
                        break;
                    default:
                        break;
                }
            }
            this.setState({
                update: false
            })

        } catch (error) {

            this.setState({
                showAlert: true,
                styelAlert: "danger",
                mensajeAlert: "Ocurrió un error, verifique su información",
                isError: true,
            });

        }
    }

    checkStatus = async (data) => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/status/participant/${data.participantId}/code/${data.accessCode}/${data.isCandidate}`;
            const fetchDataInterviewer = HeaderGet(url, tokenVideo);
            
            let res = await axiosExcecute(fetchDataInterviewer);
            
            return res.data;
        } catch (error) {
            message.error("Ocurrió un error al verificar el estatus de la agenda y del participante.");
            return false
        }
    }

    /**
     * Método que envia los datos requeridos para cancelar una entrevista 
     * @param {Objeto} form 
     */
    hondleFormCancelar = async (form) => {
        let data = {
            accessCode: this.state.accessCode,
            statusId: this.state.status,
            participantId: this.state.userId.toString(),
            isCandidate: this.state.isCandidate,
            timeZoneId: form.timeZoneId,
            reasonsProposed: form.motivos,
            proposedDate: form.proposedDate
        }

        try {
            this.setState({ loading: true });
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/proposed`;
            const fetchMeeting = HeaderPut(url, tokenVideo, data);

            let reponse = await axiosExcecute(fetchMeeting);
            
            if (reponse.status === 200) {
                switch (reponse.data) {
                    // exito
                    case 200:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "success",
                            mensajeAlert: "Sus datos se enviaron de forma exitosa",
                            habilitarBoton: true
                        });
                        break;
                    // la agenda esta en curso
                    case 300:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se pudo realizar la propuesta, la entrevista esta en curso.",
                            habilitarBoton: true
                        });
                        break;
                    // no se encontro la entrevista
                    case 400:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se encontro la entrevista o el participante, verifique su información.",
                            habilitarBoton: true
                        });
                        break;
                    // la entrevista ya termino
                    case 600:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se pudo realizar la propuesta, la entrevista concluyó.",
                            habilitarBoton: true
                        });
                        break;
                    default:
                        break;
                }

            }
        } catch (error) {

            this.setState({
                showAlert: true,
                styelAlert: "danger",
                mensajeAlert: "Ocurrió un error, verifique su información",
                habilitarBoton: false,
                msjButton: "Enviado",
                loading: false
            });

        }
    }

    /**
     * Método que envia los datos requeridos para posponer una entrevista 
     * @param {Objeto} form 
     */
    handleFormPosponer = async (form) => {

        let data = {
            accessCode: this.state.accessCode,
            statusId: this.state.status,
            participantId: this.state.userId.toString(),
            isCandidate: this.state.isCandidate,
            proposedStartTime: form.proposedStartTime,
            proposedEndTime: form.proposedEndTime,
            timeZoneId: form.timeZoneId,
            reasonsProposed: form.motivos,
            proposedDate: form.proposedDate
        }

        try {
            this.setState({ loading: true });
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/proposed`;
            const fetchMeeting = HeaderPut(url, tokenVideo, data);

            let reponse = await axiosExcecute(fetchMeeting);
            
            if (reponse.status === 200) {
                switch (reponse.data) {
                    // exito
                    case 200:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "success",
                            mensajeAlert: "Sus datos se enviaron de forma exitosa",
                            habilitarBoton: true
                        });
                        break;
                    // la agenda esta en curso
                    case 300:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se pudo realizar la propuesta, la entrevista esta en curso.",
                            habilitarBoton: true
                        });
                        break;
                    // no se encontro la entrevista
                    case 400:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se encontro la entrevista o el participante, verifique su información.",
                            habilitarBoton: true
                        });
                        break;
                    // la entrevista ya termino
                    case 600:
                        this.setState({
                            showAlert: true,
                            msjButton: "Enviado",
                            loading: false,
                            styelAlert: "danger",
                            mensajeAlert: "No se pudo realizar la propuesta, la entrevista concluyó.",
                            habilitarBoton: true
                        });
                        break;
                    default:
                        break;
                }

            }
        } catch (error) {

            this.setState({
                showAlert: true,
                styelAlert: "danger",
                mensajeAlert: "Ocurrió un error, verifique su información",
                habilitarBoton: false,
                msjButton: "Enviado",
                loading: false
            });

        }
    }

    /**
     * Método que quita la advertencia ya sea de exito o de error en caso de presentarse
     */
    cerrarAlertSwitch = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {
        const { pospuesto } = this.state
        return (
            <div className="">
                {this.state.status === 11 &&
                    <ComponenteConfirmacion
                        msjUpdateFail={this.state.update}
                        mensaje={this.state.mensajeAlert}
                        imagen={this.state.isError ? rechazo : confirmacionimg}
                        isError={this.state.isError}
                        nombreEmpresa={this.state.nombreEmpresa} />
                }
                {this.state.status === 13 &&
                    <ComponenteRechazo
                        msjUpdateFail={this.state.update}
                        mensaje={this.state.rechazar}
                        imagen={rechazo}
                        accessCode={this.state.accessCode}
                        habilitarAccion={this.state.habilitarBoton}
                        loading={this.state.loading}
                        msjButton={this.state.msjButton}
                        handleForm={this.hondleFormCancelar}
                        showAlert={this.state.showAlert}
                        styelAlert={this.state.styelAlert}
                        mensajeAlert={this.state.mensajeAlert}
                        closeAlert={this.cerrarAlertSwitch}
                        nombreEmpresa={this.state.nombreEmpresa}/>
                }
                {this.state.status === 12 &&
                    <ComponentePosponer
                        msjUpdateFail={this.state.update}
                        mensaje={pospuesto}
                        imagen={espera}
                        handleForm={this.handleFormPosponer}
                        showAlert={this.state.showAlert}
                        styelAlert={this.state.styelAlert}
                        mensajeAlert={this.state.mensajeAlert}
                        closeAlert={this.cerrarAlertSwitch}
                        accessCode={this.state.accessCode}
                        habilitarAccion={this.state.habilitarBoton}
                        loading={this.state.loading}
                        msjButton={this.state.msjButton}
                        nombreEmpresa={this.state.nombreEmpresa} />
                }
            </div>
        )
    }
}

export default Confirmacion;