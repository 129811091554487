import React from 'react';
import { Card, Col, Container, Row, Alert, Spinner, Button } from 'react-bootstrap';
import PageError from "../pagesInfo/error";
import imgReloj from '../../images/reloj.png'
import { _SERVER, _MIN_CREATE_SCHEDULE, _MAX_MIN_TIME_SCHEDULE, _MIN_MIN_TIME_SCHEDULE } from '../../settings';
import "antd/dist/antd.css";

import { TimePicker } from 'antd';
import moment from 'moment';
// ejecutor axios
import axiosExcecute from '../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../lib/cookieSession';
// objetos fetch
import { HeaderGet } from '../../lib/fecthObject';

const format = 'HH:mm';
const axios = require('axios');

class ComponentePosponer extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        mensaje: this.props.mensaje,
        imagen: this.props.imagen,
        horaActual: "",
        horaFin: "",
        fechaActual: "",
        dateTime: {
            StartTime: "00:00",
            EndTime: "00:00"
        },
        fechaActualParaValidacion: "",
        tiempoDuracionEntrevista: false,
        timeZoneId: "",
        timeZone: [],
        motivos: "",
        accessCode: this.props.accessCode,
        campoRequeridoFecha: "ocultar",
        campoRequeridoHoraInicio: "ocultar",
        campoRequeridoHoraFin: "ocultar",
        campoRequeridoZonaHoraria: "ocultar",
        campoRequeridoMotivo: "ocultar",
        showAlert: true,
        mensajeAlerta:"",
    }

    /**
     * Componente que se ejecuta al iniciar la pagina
     * 
     * Método que inicializa la fecha, la hora de inicio y hora de fin tomando en cuenta
     * la validación de una hora mínimo de duración de entrevista 
     */
    componentDidMount = async () => {
        await this.getTimeZone();
        await this.getDateSchedule();
        let fecha = new Date();
        let fechaActual = "";
        let horaActual = "";
        let horaActualFin = "";

        if (this.state.fechaActual != null && this.state.fechaActual != "") {
            fecha = this.state.fechaActual;
            horaActual = this.valueLenght(this.state.horaActual.getHours()) + ":" + this.valueLenght(this.state.horaActual.getMinutes());
            horaActualFin = this.valueLenght(this.state.horaFin.getHours()) + ":" + this.valueLenght(this.state.horaFin.getMinutes());
        }
        else {
            fecha.setMinutes(fecha.getMinutes() + _MIN_CREATE_SCHEDULE);
            var horaCierre = new Date(fecha);
            // agregamos los minutos minimos que debe tener una entrevista
            horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
            horaActual = this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes());
            horaActualFin = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes());
        }
        fechaActual = fecha.getFullYear() + "-" + this.valueLenght(fecha.getMonth() + 1) + "-" + this.valueLenght(fecha.getDate());

        this.setState({
            fechaActual: fechaActual,
            horaActual: horaActual,
            dateTime: {
                ...this.state.dateTime,
                InterviewDate: fechaActual,
                StartTime: horaActual,
                EndTime: horaActualFin,
            },
            fechaActualParaValidacion: fechaActual,
        })
    }

    /**
     * Método que obtiene las fechas, hora de inicio y fin de la entrevista
     */
    getDateSchedule = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/date/accessCode/${this.state.accessCode}`;
            const fetchSchedule = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchSchedule);

            if (res.status == 200) {
                var fechaActual = new Date();
                // agregamos los minutos minimos para crear una agenda
                fechaActual.setMinutes(fechaActual.getMinutes() + _MIN_CREATE_SCHEDULE);

                if (this.compareDate(fechaActual, new Date(res.data.startTime)) === 0) {

                    this.setState({
                        fechaActual: new Date(res.data.startTime),
                        horaActual: new Date(res.data.startTime),
                        horaFin: new Date(res.data.endTime)
                    })
                }

                this.setState({
                    timeZoneId: res.data.timeZone,
                });
            }
        } catch (error) {
        }
    }

    /**
     * Método que compara fechas (fecha1 y fecha2)
     * @param {*Tipo de dato date que identifica a la fecha 1} date1 
     * @param {*Tipo de dato date que identifica a la fecha 2} date2 
     * @returns 1 si date1 es igual o mayor a date2, 0 si date2 es mayor a date1
     */
    compareDate = (date1, date2) => {
        var DateInicializada1 = new Date(date1);
        var DateInicializada2 = new Date(date2);

        if (DateInicializada1 >= DateInicializada2) {
            return 1;
        }
        else {
            return 0
        }
    }

    /**
     * Método que obtiene la lista de zonas horarias
     */
    getTimeZone = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/zonas/horarias`;
            const fetchTimeZone = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchTimeZone);

            if (res.data.length > 0) {
                this.setState({
                    timeZone: res.data,
                    timeZoneId: res.data[30].timeZoneTypeId
                })
            }
        } catch (error) {
        }
    }

    /**
     * Método que valida y convierte un valor para hora o minutos en una cifra de dos dígitos 
     * @param {*Tipo de dato int que identifica un valor entero} value 
     * @returns string de dos dígitos
     */
    valueLenght(value) {
        return value <= 9 ? "0" + value : value;
    }

    handleHorario = async (event) => {

        if (event.target.name.includes('EndTime')) {
            await this.validarHoraFin(event.target.value);
        } else if (event.target.name.includes('StartTime')) {
            await this.validarHoraInicio(event.target.value);
        } else {
            await this.validarFecha(event.target.value);
        }
    }

    /**
     * Método que valida la hora de fin de la agenda.
     * @param {Tipo de dato string que identifica al valor seleccionado del datePicker de hora termino} value 
     * @returns string con la hora correcta para fin de la etrevista (validación de 1 hora mínima y 2 horas máxima)
     */
    validarHoraFin = (value) => {
        let confirmar = false;
        if (value === "" || value === null || value === undefined) {
            confirmar = true;
            return confirmar
        } else {

            let horaFinmayor;
            let horaInicio = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.StartTime);
            let horafin = new Date(this.state.dateTime.InterviewDate + " " + value);
            horaFinmayor = this.valueLenght(horaInicio.getHours()) + ":" + this.valueLenght(horaInicio.getMinutes()) + ":" + this.valueLenght(0);
            if (horafin.getHours() <= horaInicio.getHours()) {
                var horaCierre = new Date(horaInicio);
                // agregamos los minutos minimos que debe tener una entrevista
                horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                horaFinmayor = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);
            } else {
                var milisegundosInicio = horaInicio.getTime();
                var milisegundosFinal = horafin.getTime();
                var diferenciaMilisegundos = milisegundosFinal - milisegundosInicio;
                var diferenciaMinutos = Math.floor((diferenciaMilisegundos/1000)/60);
                var horaCierre = new Date(horaInicio);
                // agregamos los minutos minimos que debe tener una entrevista
                horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                if (horaCierre >= horafin) {
                    horaFinmayor = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);
                }
                else {
                    if (diferenciaMinutos > _MAX_MIN_TIME_SCHEDULE) {
                        var horasValidas = "";
                        var horas = Math.floor(_MAX_MIN_TIME_SCHEDULE / 60);
                        var minutos = Math.floor(_MAX_MIN_TIME_SCHEDULE % 60);
                        if (horas > 0) {
                            horasValidas = horas + " hrs";
                        }
                        if (minutos > 0) {
                            if (horasValidas === "") {
                                horasValidas = this.valueLenght(minutos)+" min";
                            }else{
                                horasValidas += " y "+ this.valueLenght(minutos)+" min";
                            }
                        }
                        
                        var mensajeAlertaHora = "Ha excedido el tiempo máximo de la entrevista: "+horasValidas;
                        this.setState({
                            tiempoDuracionEntrevista: true,
                            mensajeAlerta: mensajeAlertaHora
                        });

                        horaFinmayor = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);
                    } else {
                        horaFinmayor = this.valueLenght(horafin.getHours()) + ":" + this.valueLenght(horafin.getMinutes()) + ":" + this.valueLenght(0);
                    }
                }

            }
            this.setState({
                dateTime: {
                    ...this.state.dateTime,
                    EndTime: horaFinmayor,
                },
            });
            return confirmar
        }
    }

    /**
     * Método que valida la fecha propuesta para la entrevista
     * @param {Tipo de dato string que identifica a la fecha propuesta de la entrevista} value 
     * @returns bool true para indicar que el valor es valido o false de no valido.
     */
    validarFecha = (value) => {
        let confirmar = false;
        if (value === "" || value === null || value === undefined) {
            confirmar = true;
            return confirmar
        } else {
            let horaActual = this.state.horaActual;
            let horaFinmayor;
            let fecha = new Date();
            let fechaActual = fecha.getFullYear() + "-" + this.valueLenght(fecha.getMonth() + 1) + "-" + fecha.getDate();
            // comparamos la fecha actual con la fecha seleccionada
            // si son iguales a la hora de inicio se le suman 2 horas y a la de fin 3 horas
            if (fechaActual === value) {
                fecha.setMinutes(fecha.getMinutes() + _MIN_CREATE_SCHEDULE);
                var horaCierre = new Date(fecha);
                // agregamos los minutos minimos que debe tener una entrevista
                horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                horaActual = this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
                horaFinmayor = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);

            } else {
                var horaCierre = new Date(fecha);
                // agregamos los minutos minimos que debe tener una entrevista
                horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                // si son diferentes la hora actual se queda igual y a la de fin se le suma 1 hora
                horaActual = this.valueLenght(fecha.getHours()) + ":" + this.valueLenght(fecha.getMinutes()) + ":" + this.valueLenght(0);
                horaFinmayor = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);
            }
            this.setState({
                fechaActualParaValidacion: value,
                dateTime: {
                    ...this.state.dateTime,
                    InterviewDate: value,
                    StartTime: horaActual,
                    EndTime: horaFinmayor,
                },
            });
            return confirmar
        }
    }

    /**
     * Método que valida la hora de inicio propuesta para la entrevista
     * @param {Tipo de dato string que identifica a la hora de inicio seleccionada} value 
     * @returns true de valido, false de no valido
     */
    validarHoraInicio = (value) => {
        let confirmar = false;
        if (value == "" || value == null || value == undefined) {
            confirmar = true;
            return confirmar
        } else {

            // obtenemos la hora de inicio
            let fechaHoraInicio = new Date(this.state.dateTime.InterviewDate + " " + value);
            // obtenemos la fecha actual
            let fechaActualValida = new Date();
            let fechaFinDia = new Date(this.state.dateTime.InterviewDate + " " + value);
            fechaFinDia.setHours(23);
            fechaFinDia.setMinutes(59);
            fechaFinDia.setMilliseconds(59);

            let fecha11 = new Date(this.state.dateTime.InterviewDate + " " + value);
            fecha11.setHours(23);
            fecha11.setMinutes(0);
            fecha11.setMilliseconds(0);
            // agregamos a la fecha actual dos horas para hacer la validación
            fechaActualValida.setMinutes(fechaActualValida.getMinutes() + _MIN_CREATE_SCHEDULE);
            fechaActualValida.setSeconds(0);
            let horaInicio = "";
            let horaFin = "";
            // si la hora de inicio seleccionada es igual o mayor a la hora con las dos horas agregadas la hora de inicio se deja igual
            // a la hora de fin se le agrega 1 hora
            if (fechaHoraInicio >= fechaActualValida) {
                var horaCierre = new Date(fechaHoraInicio);
                // agregamos los minutos minimos que debe tener una entrevista
                horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                horaInicio = this.valueLenght(fechaHoraInicio.getHours()) + ":" + this.valueLenght(fechaHoraInicio.getMinutes()) + ":" + this.valueLenght(0);
                horaFin = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);

                if (fechaHoraInicio.getTime() == fecha11.getTime()) {
                    horaFin = this.valueLenght(fechaHoraInicio.getHours() + 2) + ":" + this.valueLenght(fechaHoraInicio.getMinutes()) + ":" + this.valueLenght(0);
                }
            }
            // de lo contrario se toma como referencia a la hora de inicio con las dos horas agregadas 
            // y la hora de fin se le agrega 1 hora
            else {
                if (fechaActualValida < fechaFinDia) {
                    var horaCierre = new Date(fechaActualValida);
                    // agregamos los minutos minimos que debe tener una entrevista
                    horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                    horaInicio = this.valueLenght(fechaActualValida.getHours()) + ":" + this.valueLenght(fechaActualValida.getMinutes()) + ":" + this.valueLenght(0);
                    horaFin = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);

                    if (fechaActualValida.getTime() == fecha11.getTime()) {
                        var horaCierre = new Date(fechaActualValida);
                        // agregamos los minutos minimos que debe tener una entrevista
                        horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE + _MIN_CREATE_SCHEDULE);
                        horaFin = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()) + ":" + this.valueLenght(0);
                    }
                }
                else {
                    var horaCierre = new Date(fechaFinDia);
                    // agregamos los minutos minimos que debe tener una entrevista
                    horaCierre.setMinutes(horaCierre.getMinutes() + _MIN_MIN_TIME_SCHEDULE);
                    horaInicio = this.valueLenght(fechaFinDia.getHours()) + ":" + this.valueLenght(fechaFinDia.getMinutes()+ _MIN_CREATE_SCHEDULE) + ":" + this.valueLenght(0);
                    horaFin = this.valueLenght(horaCierre.getHours()) + ":" + this.valueLenght(horaCierre.getMinutes()+ _MIN_CREATE_SCHEDULE) + ":" + this.valueLenght(0);
                }
            }
            this.setState({
                dateTime: {
                    ...this.state.dateTime,
                    StartTime: horaInicio,
                    EndTime: horaFin,
                },
            });

            return confirmar
        }
    }

    /**
     * Método que identifica el valor seleccionado de select de zonas horarias
     * @param {evento de select de zonas horarias} event 
     */
    onChangeSelectTimeZone = event => {
        this.setState({
            timeZoneId: event.target.value
        });
    }

    /**
     * Método que cierra el modal de advertencia para indicar que las horas no son validas.
     */
    handleCloseModalErrors = () => {
        this.setState({
            tiempoDuracionEntrevista: false,
        });
    }

    /**
     * Método que valida que los campos requeridos del formulario esten llenos
     */
    handleSendForm = () => {
        let horafinal = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.EndTime);
        let horaInicio = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.StartTime);
        let fechaEntrevista = new Date(this.state.dateTime.InterviewDate + " " + this.state.dateTime.StartTime);

        // validadores para fechas, identifican si son validos o no.
        var validEnd = !isNaN(horafinal.valueOf());
        var validStart = !isNaN(horaInicio.valueOf());
        var validfechaEntrevista = !isNaN(fechaEntrevista.valueOf());
        // si todo es valido hacemos el registro
        if (validEnd && validStart && validfechaEntrevista && this.state.timeZoneId != null && this.state.timeZoneId != "" && this.state.motivos != null && this.state.motivos != "") {
            this.setState({
                campoRequeridoFecha: "ocultar",
                campoRequeridoHoraInicio: "ocultar",
                campoRequeridoHoraFin: "ocultar",
                campoRequeridoZonaHoraria: "ocultar",
                campoRequeridoMotivo: "ocultar",
            });

            var form = {
                proposedStartTime: horaInicio,
                proposedEndTime: horafinal,
                timeZoneId: this.state.timeZoneId,
                motivos: this.state.motivos,
                proposedDate: fechaEntrevista,
            }
            this.props.handleForm(form);
        }
        else {
            if (!validfechaEntrevista) {
                this.setState({
                    campoRequeridoFecha: "label-advertencia"
                });
            } else {
                this.setState({
                    campoRequeridoFecha: "ocultar"
                });
            }
            if (!validStart) {
                this.setState({
                    campoRequeridoHoraInicio: "label-advertencia",
                });
            } else {
                this.setState({
                    campoRequeridoHoraInicio: "ocultar",
                });
            }
            if (!validEnd) {
                this.setState({
                    campoRequeridoHoraFin: "label-advertencia"
                });
            } else {
                this.setState({
                    campoRequeridoHoraFin: "ocultar"
                });
            }
            if (this.state.timeZoneId === "" || this.state.timeZoneId === undefined || this.state.timeZoneId === null) {
                this.setState({
                    campoRequeridoZonaHoraria: "label-advertencia"
                });
            } else {
                this.setState({
                    campoRequeridoZonaHoraria: "ocultar"
                });
            }
            if (this.state.motivos === "" || this.state.motivos === undefined || this.state.motivos === null) {
                this.setState({
                    campoRequeridoMotivo: "label-advertencia"
                });
            } else {
                this.setState({
                    campoRequeridoMotivo: "ocultar"
                });
            }
        }


        //fin de metodo de validacion 

    }


    /**
     * Formateamos una fecha GMT a UTC, pora tenen un horaerio mas exacto
     * @param {*} fecha 
     */
    convertTimeUTC(fecha) {
        var objFecha = new Date();
        objFecha.setUTCDate(fecha.getDate());
        objFecha.setUTCMonth(fecha.getMonth());
        objFecha.setUTCFullYear(fecha.getFullYear());
        objFecha.setUTCHours(fecha.getHours());
        objFecha.setUTCMinutes(fecha.getMinutes());
        objFecha.setUTCSeconds(fecha.getSeconds());
        return objFecha
    }

    /**
     * Método que obtiene el valor del input de comentarios
     * @param {evento del input de motivos} event 
     */
    handleChange = event => {
        this.setState({
            motivos: event.target.value,
        });

    }

    /**
     * Método que inbdetifica cuando se selecciona una hora de inicio
     * @param {tipo de dato time que identifica la hora} time 
     * @param {tipo de dato string que identifica a la hora} timeString 
     */
    onTimeChangeStartTime = (time, timeString) => {
        this.validarHoraInicio(timeString);
    }

    /**
     * Método que inbdetifica cuando se selecciona una hora de fin
     * @param {tipo de dato time que identifica la hora} time 
     * @param {tipo de dato string que identifica a la hora} timeString 
     */
    onTimeChangeEndTime = (time, timeString) => {
        this.validarHoraFin(timeString);
    }

    render() {
        const { mensaje, imagen } = this.state
        return (
            <div className="mt-5 pt-2">

                <section className="mt-5 contendor-confirmacion-posponer container">
                    <div className="row ">
                        <img className="pt-2 contendor-confirmacion__imagen mx-auto d-block" src={imagen} />
                    </div>
                    <div className="row titulo-posponer">
                        <div className="col-md-12 center titulo-posponer__principal">
                            <label >{mensaje}</label>
                        </div>
                        <div className="col-md-12 center titulo-posponer__subtitulo">
                            <label >Indica una propuesta de horario:</label>
                        </div>
                    </div>

                    <div className="contenedor-form" >
                        <Row className="justify-content-md-center">
                            <Col md={11} className="alinear-centro">
                                <div className="contendor-confirmacion-inputs">
                                    <label className="etiquetas-input">Día</label><br />
                                    <div className="w-100">
                                        <input
                                            className="form-control fuente-texto"
                                            type="date"
                                            name="InterviewDate"
                                            min={this.state.fechaActual}
                                            value={this.state.dateTime.InterviewDate || this.state.fechaActual}
                                            onChange={this.handleHorario}
                                        />
                                        <label className={this.state.campoRequeridoFecha}>El campo es requerido</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md={11} className="alinear-centro">
                                <div className="contendor-confirmacion-inputs">
                                    <Row className="justify-content-md-center">
                                        <Col md={6}>
                                            <label className="etiquetas-input">Inicia</label><br />
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <TimePicker
                                                        className="w-100  fuente-texto pl-0 pr-0"
                                                        showNow={false}
                                                        // clearText
                                                        placeholder="Horario"
                                                        onChange={this.onTimeChangeStartTime}
                                                        value={moment(`${this.state.dateTime.StartTime}`, 'HH:mm')}
                                                        format={format}
                                                        size="large"
                                                        showNow={true}
                                                    />
                                                    <label className={this.state.campoRequeridoHoraInicio}>El campo es requerido</label>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <label className="etiquetas-input">Termina</label><br />
                                            <div className="form-group row">
                                                <div className="col-12">
                                                
                                                    <TimePicker
                                                        className=" w-100 fuente-texto pl-0 pr-0"
                                                        showNow={false}
                                                        placeholder="Horario"
                                                        onChange={this.onTimeChangeEndTime}
                                                        format={format}
                                                        value={moment(`${this.state.dateTime.EndTime}`, format)} size="large"
                                                    />
                                                    <label className={this.state.campoRequeridoHoraFin}>El campo es requerido</label>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mb-2">
                            <Col md={11} className="alinear-centro">
                                <div className="contendor-confirmacion-inputs">
                                    <select name="TimeZone" value={this.state.timeZoneId} onChange={this.onChangeSelectTimeZone} className="form-control contendor-confirmacion-inputs--colorAqua">
                                        {
                                            this.state.timeZone.map((item, i) =>
                                                <option key={i} value={item.timeZoneTypeId}>{item.description}</option>
                                            )
                                        }
                                    </select>
                                    <label className={this.state.campoRequeridoZonaHoraria}>El campo es requerido</label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center">
                            <Col md={11} className="alinear-centro">
                                <div className="contendor-confirmacion-inputs">
                                    <label className="etiquetas-input">Motivo</label><br />
                                    <textarea className="motivos-propuesta" maxlength="950" name="posponer" rows="4" placeholder="Ej. Ha surgido un imprevisto, se reagenda la entrevista lo antes posible." onChange={this.handleChange}></textarea>
                                    <label className={this.state.campoRequeridoMotivo}>El campo es requerido</label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center pb-4">
                            <Col md={11} className="alinear-centro">
                                {this.props.showAlert === true &&
                                    <div className="contendor-confirmacion-inputs">
                                        <Alert style={{ minHeight: "48px" }} variant={this.props.styelAlert} onClose={this.props.closeAlert} dismissible className="estiloAlert mt-4 mb-0">
                                            <p className="p-0">{this.props.mensajeAlert}</p>
                                        </Alert>
                                    </div>
                                }
                            </Col>
                            <Col md={11} className="mt-2 alinear-centro">
                                {this.props.loading ?
                                    <Button variant="primary" disabled className="btn-block boton-posponer">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}Guardando...
                                    </Button>
                                    : <button className="btn btn-block btn-primary mb-4 boton-posponer boton-posponer__titulo" type="button" onClick={() => this.handleSendForm()} disabled={this.props.habilitarAccion} >
                                        {this.props.msjButton}
                                    </button>
                                }
                            </Col>
                        </Row>
                    </div>
                </section>
                { this.state.tiempoDuracionEntrevista ? <PageError onHide={this.handleCloseModalErrors} imgError={imgReloj} msj={this.state.mensajeAlerta} /> : null}
            </div>
        )
    }
}

export default ComponentePosponer;