import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import iconoInterrogacion from "../../images/interrogacion.svg";

function EstoyListo(props) {
    const {isCandidate, interviewers} = props;

    const pluralizeInterviewers = () => {
        if (interviewers > 1) {
            return 'los entrevistadores';
        } else {
            return 'el entrevistador';
        }
    }

    return(
        <Card className="border-0 pt-0">
            <Card.Body className="pt-0">
                <Row>
                    <Col sm={12} className="interrogacion">
                        <img className="interrogacion__imagen" src={iconoInterrogacion} alt=""/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="text-center seccion-informacion">
                        <p className="seccion-informacion__title">¿Estás listo para iniciar la llamada?</p>
                        <p className="seccion-informacion__mensaje">La llamada iniciará en el momento que tú y {isCandidate ? pluralizeInterviewers() : 'el candidato'} indiquen que están <br/>listos. Esto para no contar el tiempo hasta que sea necesario.</p>
                        <button className="seccion-informacion__boton" onClick={props.listo}>¡Estoy Listo!</button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default EstoyListo;